import { getError } from "@/utils/helpers";
import DefinicionesService from "@/services/DefinicionesService";

export const namespaced = true;

export const state = {
  bancos: [],
  ut: {},
  tipoDocumento: [],
  cuentas: [],
  impuestosSanciones: [],
  impuestoSancionMeta: null,
  utes: [],
  utesMeta: null,
  meta: null,
  loading: false,
  error: null,
  utev: 0.8,
};

export const mutations = {
  SET_BANCOS(state, bancos) {
    state.bancos = bancos;
  },
  SET_UT(state, ut) {
    state.ut = ut;
  },
  SET_TIPODOCUMENTO(state, tipoDocumento) {
    state.tipoDocumento = tipoDocumento;
  },
  SET_CUENTAS(state, cuentas) {
    state.cuentas = cuentas;
  },
  SET_IMPUESTOS_SANCIONES(state, impuestosSanciones) {
    state.impuestosSanciones = impuestosSanciones;
  },
  SET_META_IMPUESTOS_SANCIONES(state, meta) {
    state.impuestoSancionMeta = meta;
  },
  SET_UTES(state, utes) {
    state.utes = utes;
  },
  SET_META_UTES(state, meta) {
    state.utesMeta = meta;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_UTEV(state, utev) {
    state.utev = utev;
  },
};

function setBancos(commit, response) {
  commit("SET_BANCOS", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LOADING", false);
}

function setUT(commit, response) {
  commit("SET_UT", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LOADING", false);
}

function setTipoDocumento(commit, response) {
  commit("SET_TIPODOCUMENTO", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LOADING", false);
}

function setCuentas(commit, response) {
  commit("SET_CUENTAS", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LOADING", false);
}

function setImpuestosSanciones(commit, response) {
  commit("SET_IMPUESTOS_SANCIONES", response.data.data);
  commit("SET_META_IMPUESTOS_SANCIONES", response.data.meta);
  commit("SET_LOADING", false);
}

function setUtes(commit, response) {
  commit("SET_UTES", response.data.data);
  commit("SET_META_UTES", response.data.meta);
  commit("SET_LOADING", false);
}

function setUtev(commit, response) {
  commit("SET_UTEV", response.data.data);
  // commit("SET_META_UTES", response.data.meta);
  commit("SET_LOADING", false);
}

export const actions = {
  getBancos({ commit }) {
    commit("SET_LOADING", true);
    DefinicionesService.getBancos()
      .then((response) => {
        setBancos(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getUT({ commit }) {
    commit("SET_LOADING", true);
    DefinicionesService.getUT()
      .then((response) => {
        setUT(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getTipoDocumento({ commit }) {
    commit("SET_LOADING", true);
    DefinicionesService.getTipoDocumento()
      .then((response) => {
        setTipoDocumento(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  getCuentas({ commit }) {
    commit("SET_LOADING", true);
    DefinicionesService.getCuentas()
      .then((response) => {
        setCuentas(commit, response);
      })
      .catch((error) => {
        commit("SET_LOADING", false);
        commit("SET_ERROR", getError(error));
      });
  },
  async getImpuestosSanciones({ commit }, page) {
    try {
      commit("SET_LOADING", true);
      const response = await DefinicionesService.getImpuestosSanciones(page);
      setImpuestosSanciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
  async getImpuestoSancionPorModulo({ commit }, modulo) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.getImpuestoSancionPorModulo(
        modulo
      );
      commit("SET_IMPUESTOS_SANCIONES", response.data.data);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      console.error(error);
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async createImpuestoSancion({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.createImpuestoSancion(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async updateImpuestoSancion({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.updateImpuestoSancion(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async deleteImpuestoSancion({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.deleteImpuestoSancion(id);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async getUtes({ commit }, page) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.getUtes(page);
      setUtes(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async createUte({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.createUte(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async updateUte({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.updateUte(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async deleteUte({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.deleteUte(id);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async getUtev({ commit }) {
    commit("SET_LOADING", true);
    try {
      const response = await DefinicionesService.getUtev();
      setUtev(commit, response);
    } catch (error) {
      console.error(error);
    }
  },
};

export const getters = {
  bancos: (state) => {
    return state.bancos;
  },
  ut: (state) => {
    return state.ut;
  },
  utev: (state) => {
    return state.utev;
  },
  tipoDocumento: (state) => {
    return state.tipoDocumento;
  },
  cuentas: (state) => {
    return state.cuentas;
  },
  impuestosSanciones: (state) => {
    return state.impuestosSanciones;
  },
  impuestoSancionMeta: (state) => {
    return state.impuestoSancionMeta;
  },
  utes: (state) => {
    return state.utes;
  },
  utesMeta: (state) => {
    return state.utesMeta;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
