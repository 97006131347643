<template>
  <b-container>
    <b-modal
      id="LoteFormModal"
      ref="LoteFormModal"
      name="LoteFormModal"
      size="xl"
      centered
      hide-footer
      title="Generar Estampilla"
    >
      <b-form @submit.prevent="generarEstampilla">
        <b-row v-if="user.role === 7 || hightRole">
          <b-col>
            <b-form-group label="Denominación" label-for="denominacion">
              <b-form-input
                type="number"
                required
                v-model="lote.denominacion"
                name="denominacion"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cantidad disponible" label-for="disponible">
              <b-form-input
                type="number"
                required
                v-model="lote.disponibilidad"
                name="disponible"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cantidad" label-for="cantidad">
              <b-form-input
                type="number"
                v-model="cantidad"
                name="cantidad"
                autofocus
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Tipo de Documento" label-for="documento">
              <b-form-select
                id="documento"
                v-model="tipoDocumentoSelected"
                :options="optionTipoDocumento"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="user.role === 8 || hightRole">
          <b-col>
            <b-form-group label="Denominación" label-for="denominacion">
              <b-form-input
                type="number"
                required
                v-model="lote.denominacion"
                name="denominacion"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cantidad disponible" label-for="disponible">
              <b-form-input
                type="number"
                required
                v-model="lote.cantidad"
                name="disponible"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="user.role === 8 || hightRole">
          <b-col>
            <b-form-group label="Nombre" label-for="firstname">
              <b-form-input
                type="text"
                v-model="firstname"
                name="firstname"
                autofocus
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Apellido" label-for="lastname">
              <b-form-input
                type="text"
                v-model="lastname"
                name="lastname"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedula" label-for="dni">
              <b-form-input type="text" v-model="dni" name="dni"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Generar </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/index";
import { mapGetters } from "vuex";

export default {
  props: ["user", "lote", "hightRole", "ute"],
  data() {
    return {
      cantidad: null,
      tipoDocumentoSelected: null,
      firstname: null,
      lastname: null,
      dni: null,
    };
  },
  computed: {
    ...mapGetters("definiciones", ["ut", "tipoDocumento"]),
    optionTipoDocumento: function () {
      const docs = this.tipoDocumento;
      return docs.map((x) => {
        return {
          value: x.id,
          text: x.nombre,
        };
      });
    },
  },
  methods: {
    async generarEstampilla() {
      if (this.cantidad > 0 || this.user.role === 8) {
        const payload = {
          loteID: this.lote.id,
          cantidad: this.user.role === 8 ? 1 : this.cantidad,
          tipo: this.user.role === 8 ? "instituto" : "empresa",
          tipoDocumento: this.tipoDocumentoSelected,
          firstname: this.user.role === 8 ? this.firstname : null,
          lastname: this.user.role === 8 ? this.lastname : null,
          dni: this.user.role === 8 ? this.dni : null,
          monto:
            this.user.role === 8
              ? this.ut.valor
              : this.ut.valor * this.cantidad,
        };
        await store.dispatch("estampilla/generarEstampillasLote", payload);

        this.$emit("refreshTable", payload);
        this.$nextTick(() => {
          this.$bvModal.hide("LoteFormModal");
        });
      } else {
        this.$toast.warning("Debe indicar una cantidad valida", {
          timeout: 3000,
        });
      }
    },
  },
};
</script>

<style></style>
