<template>
  <b-container>
    <b-modal
      id="editImpuestoSancionModal"
      ref="editImpuestoSancionModal"
      name="editImpuestoSancionModal"
      size="xl"
      centered
      hide-footer
      title="Modificar Impuesto / Sanción"
    >
      <b-form @submit.prevent="modificarImpuestoSancion">
        <b-row>
          <b-col col lg="6">
            <b-form-group label="Ley" label-for="ley">
              <b-form-input
                id="ley"
                type="text"
                v-model="ley"
                autocomplete="ley"
                name="ley"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="2">
            <b-form-group label="Articulo" label-for="articulo">
              <b-form-input
                id="articulo"
                type="number"
                v-model="articulo"
                autocomplete="articulo"
                name="articulo"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="2">
            <b-form-group label="Numeral" label-for="numeral">
              <b-form-input
                id="numeral"
                type="number"
                v-model="numeral"
                autocomplete="numeral"
                name="numeral"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="2">
            <b-form-group label="Tipo" label-for="tipo">
              <b-form-select
                id="tipo"
                v-model="tipo"
                :options="optionsTipo"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col col lg="6">
            <br v-if="!conceptoLabel" />
            <b-form-group :label="conceptoLabel" label-for="concepto">
              <b-form-input
                id="concepto"
                type="text"
                v-model="concepto"
                autocomplete="concepto"
                name="concepto"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="2">
            <b-form-group label="Cantidad de UCD" label-for="cantidadUT">
              <b-form-input
                id="cantidadUT"
                type="number"
                v-model="cantidadUT"
                autocomplete="cantidadUT"
                name="cantidadUT"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="4">
            <b-form-group label="Modulo de Aplicación" label-for="modulo">
              <b-form-select
                id="modulo"
                v-model="modulo"
                :options="optionsModulo"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/index";
import { capitalize } from "@/utils/helpers.js";

export default {
  props: ["impuestoSancion"],
  data() {
    return {
      id: null,
      ley: null,
      articulo: null,
      numeral: null,
      cantidadUT: null,
      concepto: null,
      tipo: null,
      modulo: null,
      optionsTipo: [
        { value: "impuesto", text: "Impuesto" },
        { value: "sancion", text: "Sanción" },
      ],
      optionsModulo: [
        { value: "tasa_salida", text: "Tasa de Salida" },
        { value: "tasa_desinfeccion", text: "Tasa de Desinfección" },
        { value: "forma001", text: "Forma 001" },
        { value: "forma003", text: "Forma 003" },
        { value: "forma005", text: "Forma 005" },
      ],
    };
  },
  mounted() {
    this.$root.$on("bv::modal::shown", () => {
      if (this.impuestoSancion) {
        this.id = this.impuestoSancion.id;
        this.ley = this.impuestoSancion.ley;
        this.articulo = this.impuestoSancion.articulo;
        this.numeral = this.impuestoSancion.numeral;
        this.cantidadUT = this.impuestoSancion.cantidad_ut;
        this.concepto = this.impuestoSancion.concepto;
        this.tipo = this.impuestoSancion.tipo;
        this.modulo = this.impuestoSancion.modulo;
      }
    });
    /*this.$root.$on("close", () => {
      this.resetForm();
    });*/
  },
  computed: {
    conceptoLabel: function () {
      return capitalize(this.tipo);
    },
  },
  methods: {
    async modificarImpuestoSancion() {
      try {
        const payload = {
          id: this.id,
          ley: this.ley,
          articulo: this.articulo,
          numeral: this.numeral,
          cantidad_ut: this.cantidadUT,
          concepto: this.concepto,
          tipo: this.tipo,
          modulo: this.modulo,
        };
        await store.dispatch("definiciones/updateImpuestoSancion", payload);
        this.$emit("editImpuestoSancion", payload);
        this.$nextTick(() => {
          this.$bvModal.hide("editImpuestoSancionModal");
          this.resetForm();
        });
      } catch (error) {
        console.error(error);
      }
    },
    resetForm() {
      this.id = null;
      this.ley = null;
      this.articulo = null;
      this.numeral = null;
      this.cantidadUT = null;
      this.concepto = null;
      this.tipo = null;
      this.modulo = null;
    },
  },
};
</script>

<style></style>
