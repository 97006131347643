<template>
  <div>
    <Menu />
    <Info />
    <Video />
    <News />
    <Publications />
    <Footer />
    <div class="scroll-top-to">
      <i class="ti-angle-up"></i>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";
import Info from "@/components/Info.vue";
import Video from "@/components/Video.vue";
import News from "@/components/News.vue";
import Publications from "@/components/Publications.vue";

export default {
  name: "Home",
  components: {
    Menu,
    Info,
    Video,
    News,
    Publications,
    Footer,
  },
};
</script>
