<template>
  <b-container>
    <b-modal
      id="agregarUteModal"
      ref="agregarUteModal"
      name="agregarUteModal"
      size="xl"
      centered
      hide-footer
      title="Registrar UCD"
    >
      <b-form @submit.prevent="registrarUte">
        <b-row>
          <b-col col lg="6">
            <b-form-group label="Periodo" label-for="periodo">
              <b-form-input
                id="periodo"
                type="text"
                v-model="periodo"
                autocomplete="periodo"
                name="periodo"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="2">
            <b-form-group label="Valor" label-for="valor">
              <b-form-input
                id="valor"
                step="0.01"
                type="number"
                v-model="valor"
                autocomplete="valor"
                name="valor"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/index";

export default {
  data() {
    return {
      periodo: null,
      valor: null,
    };
  },
  computed: {},
  methods: {
    async registrarUte() {
      try {
        const payload = {
          periodo: this.periodo,
          valor: this.valor,
        };
        await store.dispatch("definiciones/createUte", payload);
        this.$emit("nuevoUte", payload);
        this.$nextTick(() => {
          this.$bvModal.hide("agregarUteModal");
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style></style>
