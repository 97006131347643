<template>
  <b-container class="mt-4">
    <router-link :to="{ name: 'Publicaciones' }" class="btn btn-link"
      ><i class="ti-arrow-left"></i> Volver
    </router-link>
    <h3 class="display-6 mt-4">Crear publicación:</h3>
    <form @submit.prevent="createRelease">
      <b-form-group
        id="fieldset-1"
        description="Seleccione si el tipo de publicacion es Gaceta o Noticia."
        label="Seleccione el tipo de publicación:"
        label-for="input-1"
      >
        <b-form-select v-model="blog.publicacion" class="mb-3">
          <b-form-select-option
            v-for="option in optionsTipoPublicacion"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        id="fieldset-1"
        label="Autor de la publicación:"
        label-for="input-1"
      >
        <b-form-input
          type="text"
          placeholder="Autor"
          required
          v-model="blog.autor"
          name="autor"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="fieldset-1"
        label="Título de la publicación:"
        label-for="input-1"
      >
        <b-form-input
          type="text"
          placeholder="Titulo"
          required
          v-model="blog.titulo"
          name="titulo"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fieldset-1"
        label="Contenido de la publicación:"
        label-for="input-1"
      >
        <b-form-textarea
          id="textarea-rows"
          placeholder="Contenido:"
          rows="8"
          v-model="blog.contenido"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group
        id="fieldset-1"
        label="Imagen de la publicacion:"
        label-for="input-1"
      >
        <b-form-file
          v-model="blog.imagen"
          placeholder="Seleccione una imagen..."
          drop-placeholder="Drop file here..."
          @change="select_file"
        >
        </b-form-file>
      </b-form-group>
      <b-form-group
        id="fieldset-1"
        label="Noticia destacada:"
        label-for="input-1"
      >
        <b-form-radio-group
          v-model="blog.destacado"
          :options="options"
          class="mb-3 mt-2"
          value-field="value"
          text-field="text"
        ></b-form-radio-group>
      </b-form-group>
      <b-button type="submit" class="btn-main-sm" variant="primary"
        >Publicar</b-button
      >
    </form>
  </b-container>
</template>

<script>
import PublicacionService from "@/services/PublicacionesService";

export default {
  name: "ReleaseForm",
  data() {
    return {
      blog: {
        autor: null,
        titulo: null,
        contenido: null,
        imagen: null,
        publicacion: null,
        destacado: null,
      },
      selected: null,
      optionsTipoPublicacion: [
        { value: null, text: "Por favor seleccione una opción" },
        { value: "N", text: "Noticia" },
        { value: "G", text: "Gaceta" },
      ],

      options: [
        { text: "Si", value: "S" },
        { text: "No", value: "N" },
      ],
    };
  },
  methods: {
    select_file(event) {
      this.blog.imagen = event.target.files[0];
    },
    createRelease() {
      this.error = null;
      this.message = null;
      let payload = new FormData();
      for (let key in this.blog) {
        payload.append(key, this.blog[key]);
      }

      try {
        PublicacionService.createRelease(payload)
          .then(() => {
            this.$toast.success("Publicacion creada con exito", {
              timeout: 3000,
            });
          })
          .catch(({ response }) => {
            console.error(response);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
