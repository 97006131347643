import { getError } from "@/utils/helpers";
import ClientesService from "@/services/ClientesService";

export const namespaced = true;

function setPaginatedClientes(commit, response) {
  commit("SET_CLIENTES", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export const state = {
  clientes: [],
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_CLIENTES(state, clientes) {
    state.clientes = clientes;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getClientes({ commit }, page) {
    commit("SET_LOADING", true);
    try {
      const response = await ClientesService.getClientes(page);
      setPaginatedClientes(commit, response);
      console.log(response.data.data);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
  async getCliente({ commit }, placa) {
    commit("SET_LOADING", true);
    try {
      const response = await ClientesService.getCliente(placa);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async getRecibo({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ClientesService.getRecibo(payload);
      var newBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.target = "_blank";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async crearCliente({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ClientesService.crearCliente(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async eliminarCliente({ commit, state }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await ClientesService.eliminarCliente(id);
      const clientes = getters.clientes(state);
      commit(
        "SET_CLIENTES",
        clientes.filter((x) => x.id !== id)
      );
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async paginateClientes({ commit }, page) {
    commit("SET_LOADING", true);
    try {
      const response = await ClientesService.paginateClientes(page);
      setPaginatedClientes(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async updateCliente({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ClientesService.updateCliente(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
};

export const getters = {
  clientes: (state) => {
    return state.clientes;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
