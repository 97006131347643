import Vue from "vue";
import Vuex from "vuex";

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";
import * as estampilla from "@/store/modules/Estampilla";
import * as definiciones from "@/store/modules/Definiciones";
import * as forma from "@/store/modules/Forma";
import * as report from "@/store/modules/Report";
import * as cliente from "@/store/modules/Clientes";
import * as tasa from "@/store/modules/Tasa";
import * as operacion from "@/store/modules/Operaciones";
import * as publicacion from "@/store/modules/Publicaciones";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,

  modules: {
    auth,
    user,
    estampilla,
    definiciones,
    forma,
    report,
    cliente,
    tasa,
    publicacion,
    operacion,
  },
});
