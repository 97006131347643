import { getError } from "@/utils/helpers";
import FormaService from "@/services/FormaService";
import router from "@/router";

export const namespaced = true;

function setPaginatedFormas(commit, response, type) {
  if (type === "forma001") {
    commit("SET_FORMA001", response.data.data);
  } else if (type === "forma003") {
    commit("SET_FORMA003", response.data.data);
  } else if (type === "forma005") {
    commit("SET_FORMA005", response.data.data);
  }
  commit("SET_META", { meta: response.data.meta, type });
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export const state = {
  formas001: [],
  formas003: [],
  formas005: [],
  formas001Meta: [],
  formas003Meta: [],
  formas005Meta: [],
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_FORMA001(state, formas) {
    state.formas001 = formas;
  },
  SET_FORMA003(state, formas) {
    state.formas003 = formas;
  },
  SET_FORMA005(state, formas) {
    state.formas005 = formas;
  },
  SET_META(state, { meta, type }) {
    if (type === "forma001") {
      state.formas001Meta = meta;
    } else if (type === "forma003") {
      state.formas003Meta = meta;
    } else if (type === "forma005") {
      state.formas005Meta = meta;
    }
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getFormas({ commit }, payload) {
    const { type } = payload;
    commit("SET_LOADING", true);
    try {
      const response = await FormaService.getFormas(payload);
      setPaginatedFormas(commit, response, type);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
    }
  },
  async getRecibo({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await FormaService.getRecibo(payload);
      var newBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.target = "_blank";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
    }
  },
  async solicitarForma({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await FormaService.solicitarForma(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 6000 });
      router.push("/impuestos/formas");
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
    }
  },
  async setReferenciaPago({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await FormaService.setReferenciaPago(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 6000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
    }
  },
  async setStatusSolicitud({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await FormaService.setStatusSolicitud(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 6000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
    }
  },
  async verificar({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await FormaService.verificar(payload);
      commit("SET_ESTAMPILLA", response.data.data);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
    }
  },
  async usarForma({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await FormaService.usarEstampilla(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 6000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
      throw new Error(getError(error));
    }
  },
  async eliminarSolicitud({ commit, state }, payload) {
    commit("SET_LOADING", true);
    try {
      const { id, type } = payload;
      const response = await FormaService.eliminarSolicitud(payload);
      let formas;
      if (type === "forma001") {
        formas = getters.formas001(state);
        commit(
          "SET_FORMA001",
          formas.filter((x) => x.id !== id)
        );
      } else if (type === "forma003") {
        formas = getters.formas003(state);
        commit(
          "SET_FORMA003",
          formas.filter((x) => x.id !== id)
        );
      } else if (type === "forma005") {
        formas = getters.formas005(state);
        commit(
          "SET_FORMA005",
          formas.filter((x) => x.id !== id)
        );
      }
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 6000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 6000 });
    }
  },
};

export const getters = {
  formas001: (state) => {
    return state.formas001;
  },
  formas003: (state) => {
    return state.formas003;
  },
  formas005: (state) => {
    return state.formas005;
  },
  formas001Meta: (state) => {
    return state.formas001Meta;
  },
  formas003Meta: (state) => {
    return state.formas003Meta;
  },
  formas005Meta: (state) => {
    return state.formas005Meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
