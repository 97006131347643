import * as API from "@/services/API";

export default {
  /*generarEstampilla(id) {
    return API.apiClient.get(`/generar/${id}`);
  },*/
  getEstampillas(page, filters) {
    return API.apiClient.get(`/estampillas/?page=${page}`, {
      params: {
        ...filters,
      },
    });
  },
  getLotes(page, filters) {
    return API.apiClient.get(`/estampillas/getLotes/?page=${page}`, {
      params: {
        ...filters,
      },
    });
  },
  solicitarEstampilla(payload) {
    return API.apiClient.post("/estampillas/solicitud", payload);
  },
  solicitarLote(payload) {
    return API.apiClient.post("/estampillas/solicitudLote", payload);
  },
  eliminarSolicitud(id) {
    return API.apiClient.get(`/estampillas/eliminar/${id}`);
  },
  eliminarLote(id) {
    return API.apiClient.get(`/estampillas/eliminarLote/${id}`);
  },
  setReferenciaPago(payload) {
    return API.apiClient.post("/estampillas/setReferencia", payload);
  },
  setReferenciaLote(payload) {
    return API.apiClient.post("/estampillas/setReferenciaLote", payload);
  },
  setStatusSolicitud(payload) {
    return API.apiClient.post("/estampillas/setStatusSolicitud", payload);
  },
  setStatusSolicitudLote(payload) {
    return API.apiClient.post("/estampillas/setStatusSolicitudLote", payload);
  },
  generarEstampilla(payload) {
    return API.apiClient.post("/estampillas/generarEstampilla", payload, {
      responseType: "arraybuffer",
    });
  },
  generarEstampillasLote(payload) {
    return API.apiClient.post("/estampillas/generarEstampillasLote", payload, {
      responseType: "arraybuffer",
    });
  },
  validar(payload) {
    return API.apiClient.post("/estampillas/validar", payload);
  },
  usarEstampilla(payload) {
    return API.apiClient.post("/estampillas/usarEstampilla", payload);
  },
  aprobarEstampillasExcel(payload) {
    return API.apiClient.post("/estampillas/aprobarEstampillasExcel", payload);
  },
};
