<template>
  <b-container>
    <LineChart :chart-data="datacollection" :style="customStyle"></LineChart>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import LineChart from "@/assets/js/chart/BarChart.js";

export default {
  name: "FormasChart",
  props: ["chartFilters", "customStyle"],
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: {},
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: ["23/01/2022", "24/01/2022", "25/01/2022"],
        datasets: [
          {
            label: "Forma 001",
            backgroundColor: "#2196F380",
            borderColor: "#2196F3FF",
            borderWidth: 2,
            data: [10, 20, 30],
          },
          {
            label: "Forma 003",
            backgroundColor: "#00968880",
            borderColor: "#009688FF",
            borderWidth: 2,
            data: [50, 80, 10],
          },
          {
            label: "Forma 005",
            backgroundColor: "#f87973",
            borderColor: "#2196F3FF",
            borderWidth: 2,
            data: [60, 70, 120],
          },
        ],
      };
    },
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  watch: {},
};
</script>
