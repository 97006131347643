<template>
  <b-container>
    <PieChart :chart-data="datacollection" :style="customStyle"></PieChart>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import PieChart from "@/assets/js/chart/PieChart.js";

export default {
  name: "FormasChart",
  props: ["chartFilters", "customStyle"],
  components: {
    PieChart,
  },
  data() {
    return {
      datacollection: {},
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: ["Aprobadas", "Pendientes", "Rechazadas"],
        datasets: [
          {
            backgroundColor: ["#2196F380", "#00968880", "#F87973"],
            data: [120, 80, 10],
          },
        ],
      };
    },
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  watch: {},
};
</script>
