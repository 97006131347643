<template>
  <b-container>
    <Spinner v-if="loading" />
    <b-form @submit.prevent="solicitarEstampilla">
      <b-container fluid class="estampilla-from">
        <b-row>
          <b-col cols="4"><h5>Solicitud de Estampilla</h5></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Usuario" label-for="user">
              <b-form-input
                id="user"
                type="text"
                required
                v-model="user"
                autocomplete="user"
                name="user"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedula / RIF" label-for="dni">
              <b-form-input
                id="dni"
                type="text"
                v-model="dni"
                autocomplete="dni"
                name="dni"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Periodo" label-for="periodo">
              <b-form-input
                id="periodo"
                type="text"
                required
                v-model="ut.periodo"
                name="periodo"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Valor UCD Bs." label-for="valor">
              <b-form-input
                id="valor"
                type="text"
                required
                v-model="ute2Digitos"
                name="valor"
                disabled
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Banco" label-for="banco">
              <b-form-select
                id="banco"
                v-model="cuenta"
                :options="optionCuentas"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="ucdLimiInfo" label-for="denominacion">
              <b-form-input
                id="denominacion"
                type="number"
                required
                v-model="denominacion"
                name="denominacion"
                step="0.01"
                :max="limitUcd"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Total Bs." label-for="monto">
              <b-form-input
                id="monto"
                type="number"
                required
                v-model="monto"
                name="monto"
                disabled
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Tipo de Documento" label-for="documento">
              <b-form-select
                id="documento"
                v-model="tipoDocumentoSelected"
                :options="optionTipoDocumento"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-container>
      <FlashMessage :error="error" />
    </b-form>
  </b-container>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import Spinner from "@/components/Otros/Spinner.vue";
import { getError, capitalize } from "@/utils/helpers";
import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  components: {
    FlashMessage,
    Spinner,
  },
  data() {
    return {
      user: null,
      dni: null,
      cuenta: null,
      denominacion: null,
      monto: 0,
      error: null,
      tipoDocumentoSelected: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("definiciones", ["ut", "cuentas", "tipoDocumento"]),
    ...mapGetters("estampilla", ["loading"]),
    optionCuentas: function () {
      const docs = this.cuentas;
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
    optionTipoDocumento: function () {
      const docs = this.tipoDocumento;
      return docs.map((x) => {
        return {
          value: x.id,
          text: x.nombre,
        };
      });
    },
    ute2Digitos() {
      return this.ut.valor.toFixed(2);
    },
    ucdLimiInfo: function () {
      if (this.authUser.tipoPersona === "V") {
        return "Denominacion de la Estampilla UCD Maximo x10";
      }
      return "Denominacion de la Estampilla UCD Maximo x500";
    },
    limitUcd: function () {
      if (this.authUser.tipoPersona === "V") {
        return 10;
      }
      return 50;
    },
  },
  methods: {
    async solicitarEstampilla() {
      try {
        const payload = {
          cuentas_id: this.cuenta,
          denominacion: this.denominacion,
          monto: this.monto,
          tipos_documentos_id: this.tipoDocumentoSelected,
          unidad_tributaria_id: this.ut.id,
        };
        await store.dispatch("estampilla/solicitarEstampilla", payload);
        this.$router.push({ path: "/estampillas/estampillas" });
      } catch (error) {
        this.error = getError(error);
      }
    },
    parseCuentas: function (docs) {
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
  },
  watch: {
    denominacion: function (value) {
      const monto = value * this.ut.valor;
      this.monto = monto.toFixed(2);
    },
  },
  mounted() {
    this.user = `${capitalize(this.authUser.firstname)} ${capitalize(
      this.authUser.lastname
    )}`;
    this.dni = `${this.authUser.tipoPersona}-${this.authUser.dni}`;
  },
};
</script>
