<template>
  <div>
    <Menu />
    <b-container>
      <div class="container mt-5">
        <div class="row">
          <div class="col-lg-8">
            <!-- Post content-->
            <article>
              <!-- Post header-->
              <header class="mb-4">
                <!-- Post title-->
                <h1 class="fw-bolder mb-1">{{ blog.titulo }}</h1>
                <!-- Post meta content-->
                <div class="text-muted fst-italic mb-2">
                  {{ fecha(blog.created_at) }}
                </div>
                <!-- Post categories-->
                <a
                  class="badge bg-secondary text-decoration-none text-white"
                  v-if="blog.publicacion === 'N'"
                  >Noticia</a
                >
                <a
                  class="badge bg-secondary text-decoration-none text-white"
                  v-if="blog.publicacion === 'G'"
                  >Gaceta</a
                >
                <a
                  class="badge bg-secondary text-decoration-none text-white"
                  v-if="blog.destacado === 'S'"
                  >Noticia destacada</a
                >
                <div
                  class="badge text-muted text-decoration-none fst-italic mb-2"
                >
                  Autor(a): {{ blog.autor }}
                </div>
              </header>
              <!-- Preview image figure-->
              <figure class="mb-4">
                <b-img
                  class="card-img"
                  :src="ruta + blog.imagen"
                  alt="..."
                  width="700"
                  height="320"
                />
              </figure>
              <!-- Post content-->
              <section class="mb-5">
                <p class="fs-5 mb-4" v-html="blog.contenido"></p>
              </section>
            </article>
          </div>
          <!-- Side widgets-->
          <div class="col-lg-4">
            <!-- Search widget-->
            <div class="card mb-4 rand-release">
              <div class="card-header">También puedes ver...</div>
              <div>
                <b-row no-gutters>
                  <b-col md="5">
                    <b-card-img
                      :src="ruta + rand[0].imagen"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </b-col>
                  <b-col md="7">
                    <b-card-body>
                      <b-card-text>
                        <p class="text-black">{{ rand[0].titulo }}</p>
                        <small v-if="rand[0].publicacion === 'N'">
                          <a
                            :href="'/noticias/' + rand[0].id"
                            class="link-primary"
                          >
                            Leer
                          </a>
                        </small>
                        <small v-else>
                          <a
                            :href="'/gacetas/' + rand[0].id"
                            class="link-primary"
                          >
                            Leer
                          </a>
                        </small>
                      </b-card-text>
                    </b-card-body>
                  </b-col>
                </b-row>
              </div>
            </div>
            <Categorias />
          </div>
        </div>
      </div>
    </b-container>

    <Footer />
    <div class="scroll-top-to">
      <i class="ti-angle-up"></i>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Menu from "@/components/Menu.vue";
import Categorias from "@/components/CategoriasWidget.vue";
import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "viewRelease",
  components: {
    Menu,
    Footer,
    Categorias,
  },
  data() {
    return {
      blog: {
        autor: null,
        titulo: null,
        contenido: null,
        imagen: null,
        publicacion: null,
        destacado: null,
      },
      rand: {
        titulo: null,
        contenido: null,
        imagen: null,
        publicacion: null,
        destacado: null,
      },
      ruta: process.env.VUE_APP_API_URL + "/publicaciones/",
    };
  },
  computed: {
    ...mapGetters("publicacion", ["release", "rand_release"]),
  },
  mounted() {
    this.mostrarBlog();
    this.mostrarPublicaciones();
  },
  methods: {
    async mostrarBlog() {
      try {
        await store.dispatch(
          "publicacion/getPublicacion",
          this.$route.params.id
        );
        this.blog = this.release;
      } catch (error) {
        console.log(error);
      }
    },
    async mostrarPublicaciones() {
      try {
        await store.dispatch("publicacion/getRandom");
        this.rand = this.rand_release;
      } catch (error) {
        console.log(error);
      }
    },
    fecha(fecha) {
      var date = new Date(fecha);
      console.log(date.getTime());
      var nueva_fecha =
        "Publicado el: " +
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      return nueva_fecha;
    },
  },
};
</script>

<style>
.rand-release img {
  height: 100%;
}

.link-primary {
  color: rgb(45, 146, 247);
}

.text-black {
  color: rgb(39, 35, 35);
}
</style>
