import { getError } from "@/utils/helpers";
import EstampillaService from "@/services/EstampillaService";

export const namespaced = true;

function setPaginatedLote(commit, response) {
  commit("SET_ESTAMPILLAS", response.data.data);
  commit("SET_LOTE_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

function setPaginatedEstampillas(commit, response) {
  commit("SET_ESTAMPILLAS", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export const state = {
  estampillas: [],
  estampilla: null,
  loteMeta: null,
  meta: null,
  links: null,
  loading: false,
  error: null,
  summary: [],
};

export const mutations = {
  SET_ESTAMPILLAS(state, estampillas) {
    state.estampillas = estampillas;
  },
  SET_ESTAMPILLA(state, estampilla) {
    state.estampilla = estampilla;
  },
  SET_LOTE_META(state, meta) {
    state.loteMeta = meta;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_SUMMARY(state, summary) {
    state.summary = summary;
  },
};

export const actions = {
  async getEstampillas({ commit }, { page, filters }) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.getEstampillas(page, filters);
      setPaginatedEstampillas(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
  async getLotes({ commit }, { page, filters }) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.getLotes(page, filters);
      setPaginatedLote(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
  async generarEstampilla({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.generarEstampilla(payload);
      var newBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.target = "_blank";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async solicitarEstampilla({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.solicitarEstampilla(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async solicitarLote({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.solicitarLote(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async eliminarSolicitud({ commit, state }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.eliminarSolicitud(id);
      const estampillas = getters.estampillas(state);
      commit(
        "SET_ESTAMPILLAS",
        estampillas.filter((x) => x.id !== id)
      );
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async eliminarLote({ commit, state }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.eliminarLote(id);
      const estampillas = getters.estampillas(state);
      commit(
        "SET_ESTAMPILLAS",
        estampillas.filter((x) => x.id !== id)
      );
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async setReferenciaPago({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.setReferenciaPago(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async setReferenciaLote({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.setReferenciaLote(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async setStatusSolicitud({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.setStatusSolicitud(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async setStatusSolicitudLote({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.setStatusSolicitudLote(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async validar({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.validar(payload);
      commit("SET_ESTAMPILLA", response.data.data);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async usarEstampilla({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.usarEstampilla(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
      throw new Error(getError(error));
    }
  },
  async generarEstampillasLote({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.generarEstampillasLote(payload);
      var newBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.target = "_blank";
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async aprobarEstampillasExcel({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await EstampillaService.aprobarEstampillasExcel(payload);
      commit("SET_SUMMARY", response.data.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
};

export const getters = {
  estampillas: (state) => {
    return state.estampillas;
  },
  estampilla: (state) => {
    return state.estampilla;
  },
  meta: (state) => {
    return state.meta;
  },
  loteMeta: (state) => {
    return state.loteMeta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
  summary: (state) => {
    return state.summary;
  },
};
