<template>
  <div>
    <section class="section info-section" id="news">
      <div class="container divider-section">
        <h5>Noticias Destacadas</h5>
      </div>
      <div class="container">
        <div class="row">
          <div
            class="col-lg-3 col-md-6 col-sm-12 col-xs-12"
            v-for="blog in blogs"
            :key="blog.id"
          >
            <b-card-group deck>
              <b-card
                :title="blog.titulo"
                :img-src="ruta + blog.imagen"
                img-width="253"
                img-height="143"
                img-alt="Image"
                img-top
                tag="article"
                img-class="card-publications"
              >
                >
                <b-card-text v-html="blog.contenido.substr(0, 141) + '...'">
                </b-card-text>
                <template #footer>
                  <b-col>
                    <router-link
                      :to="{ name: 'showNoticias', params: { id: blog.id } }"
                      class="btn btn-link"
                      >Leer
                    </router-link>
                  </b-col>
                  <b-col>
                    <span class="helpText">{{ fecha(blog.created_at) }}</span>
                  </b-col>
                </template>
              </b-card>
            </b-card-group>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <a
              class="twitter-timeline"
              data-lang="es"
              data-width="320"
              data-height="550"
              href="https://twitter.com/amarcanopsuv?ref_src=twsrc%5Etfw"
              >Tweets by amarcanopsuv</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script
  async
  src="https://platform.twitter.com/widgets.js"
  charset="utf-8"
></script>
<script>
/*import moment from "moment";*/
import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  components: {},
  data() {
    return {
      blogs: null,
      ruta: process.env.VUE_APP_API_URL + "/publicaciones/",
    };
  },

  computed: {
    ...mapGetters("publicacion", ["release"]),
  },
  mounted() {
    this.getRelease();
  },
  methods: {
    async getRelease() {
      try {
        await store.dispatch("publicacion/getRelease");
        this.blogs = this.release;
      } catch (error) {
        console.log(error);
      }
    },
    fecha(fecha) {
      var date = new Date(fecha);
      console.log(date.getTime());
      var nueva_fecha =
        "Publicado el: " +
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      return nueva_fecha;
    },
  },
  /*computed: {
    fecha() {
      return moment().format("DD/MM/YYYY");
    },
  },*/
};
</script>
