<template>
  <div>
    <Menu />
    <Views />
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
import Views from "@/components/ReleaseViews.vue";

export default {
  name: "Publicaciones",
  components: { Menu, Views, Footer },
};
</script>
<style>
.icon-table {
  margin: 5px;
}
</style>
