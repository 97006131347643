<template>
  <div>
    <Menu />
    <div class="dashboard-separador"></div>
    <b-container>
      <br />
      <b-row>
        <b-col>
          <h5>Marco Jurídico que rige a nuestra institución</h5>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col class="leyes-section">
          <b-list-group>
            <b-list-group-item variant="dark">
              Leyes y Reglamentos
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley de Reforma de timbre fiscal de Estado Bolívar Enero 2022
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">Codigo Orgánico Tributario</b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley Orgánica de Procedimientos administrativos
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Reglamento de la ley de timbre fiscal nacional
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley de Reforma de timbre fiscal de Estado Bolívar Enero 2022
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">Codigo Orgánico Tributario</b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley Orgánica de Procedimientos administrativos
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Reglamento de la ley de timbre fiscal nacional
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley de Reforma de timbre fiscal de Estado Bolívar Enero 2022
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">Codigo Orgánico Tributario</b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley Orgánica de Procedimientos administrativos
              </b-link>
            </b-list-group-item>
            <b-list-group-item variant="dark">
              Gacetas Oficiales del Estado Bolívar
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                N°2766 - 24 de Enero del 2022. Sobre la reforma de la Ley de
                Timbre Fiscal del Estado Bolívar</b-link
              >
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley de Reforma de timbre fiscal de Estado Bolívar Enero 2022
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">Codigo Orgánico Tributario</b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Ley Orgánica de Procedimientos administrativos
              </b-link>
            </b-list-group-item>
            <b-list-group-item>
              <b-link href="#">
                Reglamento de la ley de timbre fiscal nacional
              </b-link>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
    <br />
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Leyes",
  components: {
    Menu,
    Footer,
  },
};
</script>

<style></style>
