<template>
  <b-container>
    <b-form @submit.prevent="solicitarForma001">
      <b-container fluid class="estampilla-from">
        <b-row>
          <b-col cols="4"><h5>Solicitud de Forma 001</h5></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Usuario" label-for="user">
              <b-form-input
                id="user"
                type="text"
                required
                v-model="user"
                autocomplete="user"
                name="user"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedula / RIF" label-for="dni">
              <b-form-input
                id="dni"
                type="text"
                v-model="dni"
                autocomplete="dni"
                name="dni"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tipo de Tramite" label-for="tipoDocumento">
              <b-form-select
                v-model="tipoDocumento"
                :options="optionTramite"
                required
                class="form-control"
                @change="setLabelTramite"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Forma de Pago" label-for="formaPago">
              <b-form-select
                id="formaPago"
                v-model="formaPago"
                :options="optionFormaPago"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Banco" label-for="banco">
              <b-form-select
                id="banco"
                v-model="cuenta"
                :options="optionCuentas"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" v-if="tipoDocumento === 7">
            <b-form-group
              label="Nombre de la Actividad"
              label-for="customDocumento"
            >
              <b-form-input
                type="text"
                required
                v-model="customDocumento"
                name="customDocumento"
                placeholder="Especifique"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6" v-if="tipoDocumento === 7">
            <b-form-group :label="optionLabel" label-for="monto">
              <b-form-input
                type="number"
                required
                v-model="monto"
                name="monto"
                step="0.01"
                placeholder="Bs."
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="7" v-if="tipoDocumento !== 7">
            <b-form-group :label="optionLabel" label-for="monto">
              <b-form-input
                type="number"
                required
                v-model="monto"
                name="monto"
                step="0.01"
                placeholder="Bs."
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3" v-if="tipoDocumento === 1">
            <b-form-group label="Tipo de Licencia" label-for="tipoLicencia">
              <b-form-select
                id="tipoLicencia"
                v-model="tipoLicencia"
                :options="optionTipoLicencia"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="2" v-if="tipoDocumento === 1">
            <b-form-group label="Numero de Licencia" label-for="nroLicencia">
              <b-form-input
                type="number"
                required
                v-model="nroLicencia"
                name="nroLicencia"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="tipoDocumento">
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-container>
      <FlashMessage :error="error" />
    </b-form>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage";
import { capitalize } from "@/utils/helpers.js";
import { getError } from "@/utils/helpers";
import store from "@/store/index";
export default {
  components: {
    FlashMessage,
  },
  data() {
    return {
      error: null,
      tipoDocumento: null,
      user: null,
      dni: null,
      cuenta: null,
      formaPago: null,
      monto: null,
      tipoLicencia: null,
      nroLicencia: null,
      optionLabel: null,
      customDocumento: null,
      optionTramite: [
        { value: 1, text: "Tasa de Licores" },
        { value: 2, text: "1x1000 Por Instrumentos Crediticios" },
        { value: 3, text: "1x1000 Por Servicios y Compras" },
        { value: 4, text: "Tasa Forestales" },
        { value: 5, text: "Minerales no Metalicos" },
        { value: 6, text: "Tasa Por Servicios Sanitarios" },
        { value: 7, text: "Específique" },
      ],
      optionLabels: [
        "Instalaciones, Tramites y Renovacion de Registros y Autorizaciones de Licores",
        "Instrumentos Crediticios o Letras de Cambio Por el Total Emitido (1x1000)",
        "Ordenes de Pago Por Servicios Prestados al Sector Publico (1x1000)",
        "Por Los Servicios Tecnicos Forestales",
        "Por Actividades de Exploracion y Extraccion de Minerales Metalicos y No Metalicos",
        "Por Servicios y Documentos Sanitarios",
        "Monto",
      ],
      optionFormaPago: [
        { value: "transferencia", text: "Transferencia" },
        // { value: "pago_movil", text: "Pago Móvil" },
      ],
      optionTipoLicencia: [
        { value: 1, text: "CV - Copas y Vinos" },
        { value: 2, text: "CC - Copas y Cervezas" },
        { value: 3, text: "CV - Cervezas y Vinos" },
        { value: 4, text: "MN - Menor al detal" },
        { value: 5, text: "MY - Al mayor" },
        { value: 6, text: "DV - Distribuidor Ventas" },
      ],
    };
  },
  mounted() {
    this.user = `${capitalize(this.authUser.firstname)} ${capitalize(
      this.authUser.lastname
    )}`;
    this.dni = `${this.authUser.tipoPersona}-${this.authUser.dni}`;
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("definiciones", ["cuentas"]),
    optionCuentas: function () {
      const docs = this.cuentas;
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
  },
  methods: {
    async solicitarForma001() {
      try {
        const payload = {
          cuentas_id: this.cuenta,
          forma_pago: this.formaPago,
          monto: this.monto,
          tipo_documento: this.tipoDocumento,
          tipo_licencia: this.tipoLicencia,
          num_licencia: this.nroLicencia,
          documento: this.customDocumento,
          type: "forma001",
        };
        await store.dispatch("forma/solicitarForma", payload);
      } catch (error) {
        this.error = getError(error);
      }
    },
    setLabelTramite() {
      this.optionLabel = this.optionLabels[this.tipoDocumento - 1];
    },
  },
};
</script>

<style></style>
