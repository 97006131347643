import moment from "moment";

export const parseDate = (date) => {
  return date ? moment(date).format("DD-MM-YYYY hh:mm:ss") : date;
};

export const getError = (error) => {
  const errorMessage = "Error interno del sistema, intente nuevamente.";
  const { errors, message } = error.response.data;
  const { status } = error.response;

  if (status === 403 && message === "Your email address is not verified.") {
    return "Correo no verificado";
  }

  if (status === 422 && message === "The given data was invalid.") {
    if (Object.hasOwn(errors, "email")) {
      return error.response.data.errors.email[0];
    }
    if (Object.hasOwn(errors, "dni")) {
      return `Cedula o Rif ya se encuentra registrado`;
    }
    if (Object.hasOwn(errors, "password")) {
      if (errors.password[0].includes("8")) {
        return "Su clave debe contener al menos 8 caracteres";
      }
      return errors.password[0];
    }
  }
  if (Object.hasOwn(errors, "referencia") && status === 422) {
    return errors.referencia[0];
  }
  if (Object.hasOwn(errors, "monto") && status === 422) {
    return errors.monto[0];
  }
  if (error.name === "Fetch User") {
    return error.message;
  }
  if (message === "Email-sending-failed") {
    return "Imposible enviar el correo de verificación en estos momentos, si el problema persiste, comuníquese como soporte técnico";
  }

  if (!error.response) {
    console.error(`API ${error.config.url} not found`);
    return errorMessage;
  }
  if (process.env.NODE_ENV === "local") {
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  }
  if (error.response.data && error.response.data.errors) {
    return error.response.data.errors;
  }

  return errorMessage;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const renderReportData = (data, reportType) => {
  return data.map((item) => {
    if (reportType === "sanitaria") {
      return {
        codigo: item.codigo,
        tipo: capitalize(item.tipo),
        creacion: parseDate(item.created_at),
        empresa: item.cliente.empresa,
        rif: item.cliente.rif,
        placa: item.cliente.placa,
        monto: item.monto,
        cantidadUT: item.cantidad_ut,
        created_by: `${item.usuario.firstname} ${item.usuario.lastname}`,
        deleted_by: item.deleted_by
          ? `${item.deleted_by.firstname} ${item.deleted_by.lastname}`
          : null,
        deleted_at: parseDate(item.deleted_at),
        status: capitalize(item.status),
        nota: item.nota,
      };
    } else if (reportType === "salida") {
      return {
        codigo: item.codigo,
        tipo: capitalize(item.tipo),
        creacion: parseDate(item.created_at),
        monto: item.monto,
        cantidadUT: item.cantidad_ut,
        created_by: `${item.usuario.firstname} ${item.usuario.lastname}`,
        deleted_by: item.deleted_by
          ? `${item.deleted_by.firstname} ${item.deleted_by.lastname}`
          : null,
        deleted_at: parseDate(item.deleted_at),
        status: capitalize(item.status),
        nota: item.nota,
      };
    } else if (reportType !== "estampilla") {
      return {
        id: item.id,
        creacion: parseDate(item.creacion),
        approved_at: parseDate(item.approved_at),
        dni: `${item.usuario.tipo_persona}-${item.usuario.dni}`,
        codigo: item.codigo,
        denominacion: item.denominacion,
        monto: item.monto,
        banco: item.cuenta.banco.nombre_corto,
        referencia: item.referencia,
        status: capitalize(item.status),
      };
    } else {
      return {
        id: item.id,
        creacion: item.creacion,
        approved_at: item.approved_at,
        dni: `${item.usuario.tipo_persona}-${item.usuario.dni}`,
        codigo: item.codigo,
        monto: item.monto,
        denominacion: item.denominacion,
        banco: item.cuenta.banco.nombre_corto,
        referencia: item.referencia,
        status: capitalize(item.status),
      };
    }
  });
};

export const calcularIntervalo = (opcion) => {
  switch (opcion) {
    case 1: {
      return {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      };
    }
    case 2: {
      return {
        from: moment().subtract(1, "days").format("YYYY-MM-DD"),
        to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
    }
    case 3: {
      return {
        from: moment().subtract(6, "days").format("YYYY-MM-DD"),
        to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
    }
    case 4: {
      return {
        from: moment().subtract(14, "days").format("YYYY-MM-DD"),
        to: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
    }
  }
};
