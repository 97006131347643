<template>
  <div>
    <Menu />
    <div class="dashboard-separador"></div>
    <b-container>
      <b-row>
        <b-col col lg="6" class="my-5">
          <b-container class="nosotros-divider-section">
            <h5>Misión</h5>
          </b-container>
          <p class="nosotros-text">
            Recaudar los ingresos provenientes de la venta de timbre fiscal y
            demás tributos que le sean asignados al estado Bolívar, a través de
            la divulgación de las normas en materia tributaria estadal así como
            la fiscalización de los entes contribuyentes y responsables a los
            fines de contribuir con los planes y proyectos a ejecutar por la
            Gobernación del estado Bolívar.
          </p>
        </b-col>
        <b-col col lg="6" class="my-5 nosotros-img-cont">
          <b-img
            fluid
            :src="require('@/assets/img/nosotros/mision.jpg')"
          ></b-img>
        </b-col>
      </b-row>
      <b-row>
        <b-col col lg="6" class="my-5 nosotros-img-cont">
          <b-img
            fluid
            :src="require('@/assets/img/nosotros/vision.jpg')"
            rounded
          ></b-img>
        </b-col>
        <b-col col lg="6" class="my-5">
          <b-container class="nosotros-divider-section">
            <h5>Visión</h5>
          </b-container>

          <p class="nosotros-text">
            Consolidar el proceso de recaudación de tributos estadales y ser
            reconocidos como un servicio estratégico y fundamental para el
            desarrollo del estado Bolívar.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col col lg="6" class="my-5">
          <b-container class="nosotros-divider-section-long">
            <h5>Objetivo Estratégico Institucional</h5>
          </b-container>

          <p class="nosotros-text">
            Creación, organización, recaudación, control, administración,
            inspección y fiscalización de la renta de timbre fiscal y demás
            tributos que correspondan y sean asignados al estado Bolívar.
          </p>
        </b-col>
        <b-col col lg="6" class="my-5 nosotros-img-cont">
          <b-img
            fluid
            :src="require('@/assets/img/nosotros/objetivo.jpg')"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
    <br />
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Nosotros",
  components: {
    Menu,
    Footer,
  },
};
</script>

<style></style>
