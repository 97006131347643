import { getError } from "@/utils/helpers";
import TasaService from "@/services/TasaService";

export const namespaced = true;

function setPaginatedTasa(commit, response) {
  commit("SET_TASAS", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export const state = {
  tasas: [],
  cantidadUT: 300,
  concepto: "Tasa por servicio de desinfeción",
  tipo: "sanitaria",
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_TASAS(state, tasas) {
    state.tasas = tasas;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getTasas({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await TasaService.getTasas(payload);
      setPaginatedTasa(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
    }
  },
  async getTasa({ commit }, tasaID) {
    try {
      commit("SET_LOADING", true);
      const response = await TasaService.getTasa(tasaID);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async crearTasa({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await TasaService.crearTasa(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async anularTasa({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await TasaService.anularTasa(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async paginateTasas({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await TasaService.paginateTasas(payload);
      setPaginatedTasa(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async reportOp({ commit }, tipo) {
    try {
      commit("SET_LOADING", true);
      await TasaService.reportOp(tipo);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
};

export const getters = {
  tasas: (state) => {
    return state.tasas;
  },
  cantidadUT: (state) => {
    return state.cantidadUT;
  },
  tipo: (state) => {
    return state.tipo;
  },
  concepto: (state) => {
    return state.concepto;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
