<template>
  <b-container fluid>
    <b-row>
      <b-col col lg="12">
        <b-container fluid class="top-head">
          <b-row class="justify-content-md-center">
            <b-col col lg="3" class="text-muted">
              <small>{{ fecha }}</small>
            </b-col>
            <b-col col lg="5" class="text-muted">
              <small
                >Valor de la Unidad de Cuenta Dinamica:
                {{ utev.valor.toFixed(2) || "4" }} Bs.</small
              >
            </b-col>
            <b-col col lg="4">
              <ul class="social-icon list-inline">
                <li class="list-inline-item">
                  <small>gerenciaderecaudaciontb@gmail.com</small>
                  &nbsp;
                </li>
                <li class="list-inline-item">
                  <a href="https://twitter.com/saatbolivar"
                    ><i class="ti-twitter"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a href="https://www.instagram.com/saatbolivar/"
                    ><i class="ti-instagram"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UCApUXnjHrmQhALv6AyXX5gw"
                    ><i class="ti-youtube"></i
                  ></a>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-navbar
          id="main-menu"
          toggleable="lg"
          type="light"
          variant="faded"
          sticky
        >
          <b-container class="nav-container-custom">
            <b-row>
              <b-col col lg="2" md="2" sm="8">
                <b-navbar-brand href="/">
                  <b-img
                    :src="require('@/assets/img/logos/logo-01.png')"
                    width="150"
                  ></b-img>
                </b-navbar-brand>
              </b-col>
              <b-col col lg="8" md="8" sm="4">
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav>
                    <b-nav-item href="#">
                      <router-link to="/nosotros">Nosotros</router-link>
                    </b-nav-item>
                    <b-nav-item href="#">Planillas</b-nav-item>
                    <b-nav-item href="#">
                      <router-link to="/estampilla-info">
                        Estampillas
                      </router-link>
                    </b-nav-item>
                    <b-nav-item href="#">
                      <router-link to="/leyes">Marco Jurídico</router-link>
                    </b-nav-item>
                    <b-nav-item href="#">
                      <router-link to="/register">Registrarse</router-link>
                    </b-nav-item>
                    <b-nav-item href="#">
                      <router-link to="/login"
                        >Iniciar Sesi&oacute;n</router-link
                      >
                    </b-nav-item>
                  </b-navbar-nav>
                </b-collapse>
              </b-col>
              <b-col col lg="2" md="2" sm="6">
                <b-navbar-brand
                  toggleable="lg"
                  type="light"
                  variant="faded"
                  id="logo2"
                >
                  <b-img
                    :src="require('@/assets/img/logos/am-02.png')"
                    width="150"
                  ></b-img>
                </b-navbar-brand>
              </b-col>
            </b-row>
          </b-container>
          <div class="info-bar">
            <b-link class="icon-info icon-ute">
              UCD <br />
              {{ utev.valor.toFixed(2) || 4 }} Bs.
            </b-link>
            <!--<b-link class="icon-info icon-go">GOEB</b-link>-->
          </div>
          <div class="social-bar">
            <b-link
              class="icon-social icon-twitter"
              href="https://twitter.com/saatbolivar"
              target="_blank"
            >
              <i class="ti-twitter"></i>
            </b-link>
            <b-link
              class="icon-social icon-instagram"
              href="https://www.instagram.com/saatbolivar/"
              target="_blank"
            >
              <i class="ti-instagram"></i>
            </b-link>
            <b-link
              class="icon-social icon-youtube"
              href="https://www.youtube.com/channel/UCApUXnjHrmQhALv6AyXX5gw"
              target="_blank"
            >
              <i class="ti-youtube"></i>
            </b-link>
          </div>
        </b-navbar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import store from "@/store/index";
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.getUtev();
  },
  computed: {
    ...mapGetters("definiciones", ["utev"]),
    fecha() {
      moment.locale("es-ve");
      return moment().format("LL", "es");
    },
  },
  methods: {
    async getUtev() {
      try {
        await store.dispatch("definiciones/getUtev");
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
