<template>
  <b-container>
    <LineChart :chart-data="datacollection" :style="customStyle"></LineChart>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import LineChart from "@/assets/js/chart/BarChart.js";

export default {
  name: "EstampillasChart",
  props: ["chartFilters", "customStyle"],
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: {},
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: [
          "23/01/2022",
          "24/01/2022",
          "25/01/2022",
          "26/01/2022",
          "27/01/2022",
        ],
        datasets: [
          {
            label: "Estampillas",
            backgroundColor: "#2196F380",
            borderColor: "#2196F3FF",
            borderWidth: 2,
            data: [10, 20, 30, 80, 30],
          },
        ],
      };
    },
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
  },
  watch: {},
};
</script>
