<template>
  <b-container>
    <Spinner v-if="loading" />
    <b-container fluid class="estampilla-from">
      <b-row>
        <b-col cols="4"><h5>Usuarios registrados</h5></b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent="filtersUsers" @reset.prevent="onReset">
            <b-row>
              <b-col>
                <b-form-group label="Tipo de filtro" label-for="filterType">
                  <b-form-select
                    id="filterType"
                    v-model="customFilters.filterType"
                    :options="optionFilterType"
                    class="form-control"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="customFilters.filterType !== 'created_at'">
                <b-form-group label="Texto" label-for="search-input">
                  <b-form-input
                    id="search-input"
                    v-model="customFilters.search"
                    type="search"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="customFilters.filterType === 'created_at'">
                <b-form-group label="Intervalo" label-for="interval">
                  <b-form-select
                    id="interval"
                    v-model="interval"
                    :options="optionInterval"
                    class="form-control"
                    @change="setInterval"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col v-if="interval === 5">
                <b-form-group label="Desde" label-for="from">
                  <b-form-datepicker
                    id="from"
                    v-model="customFilters.from"
                    class="form-control"
                    locale="es"
                    label-help=""
                    label-no-date-selected="Elija Fecha"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col v-if="interval === 5">
                <b-form-group label="Hasta" label-for="to">
                  <b-form-datepicker
                    id="to"
                    v-model="customFilters.to"
                    class="form-control"
                    locale="es"
                    label-help=""
                    label-no-date-selected="Elija Fecha"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="1" class="report-buttons">
                <b-button type="submit" variant="primary"> Buscar </b-button>
              </b-col>
              <b-col cols="1" class="report-buttons">
                <b-button type="reset" variant="secondary"> Limpiar </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Roles/Permisos"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    v-model="customFilters.status"
                    :options="optionsStatus"
                    value-field="item"
                    text-field="name"
                    disabled-field="notEnabled"
                    :aria-describedby="ariaDescribedby"
                    switches
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
      <b-row>
        <b-col col lg="4">
          <b-button
            block
            variant="primary"
            @click="openModalAgregarUsuario($bvModal)"
            >Nuevo Usuario
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col col lg="6" class="my-1">
          <b-form-group
            label="Buscar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col col lg="6" v-if="meta">
          <b-pagination
            v-model="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            align="fill"
            size="sm"
            class="my-0"
            aria-controls="usersTable"
            @page-click="setClick"
          ></b-pagination>
          <b-row>
            <b-col col lg="4">
              <small>Página actual: {{ meta.current_page }}</small>
            </b-col>
            <b-col col offset-lg="5" lg="3" style="text-align: right">
              <small>Total: {{ meta.total }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            striped
            hover
            sort-icon-left
            :items="users"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(opciones)="data">
              <b-link href="#" class="icon-table">
                <b-icon
                  :id="'update' + data.item.id"
                  icon="pencil-fill"
                  scale="1"
                  variant="secondary"
                  @click="openModalEditUsuario($bvModal, data.item)"
                >
                </b-icon>
                <b-tooltip
                  :target="'update' + data.item.id"
                  title="Modificar"
                ></b-tooltip>
              </b-link>
              <b-link href="#" class="icon-table">
                <b-icon
                  :id="'eliminar' + data.item.id"
                  icon="x-circle-fill"
                  scale="1"
                  variant="danger"
                  @click="eliminarUsuario(data.item.id)"
                >
                </b-icon>
                <b-tooltip
                  :target="'eliminar' + data.item.id"
                  title="Eliminar"
                ></b-tooltip>
              </b-link>
            </template>
            <template #cell(lote)="data">
              <b-icon
                v-if="data.value === true"
                :id="'lote' + data.item.id"
                icon="files"
                scale="2"
                variant="secondary"
              >
              </b-icon>
              <b-icon
                v-else
                :id="'lote' + data.item.id"
                icon="file-x"
                scale="2"
                variant="secondary"
              ></b-icon>
              <b-tooltip
                v-if="data.value === true"
                :target="'lote' + data.item.id"
                title="Lote Habilitado"
              ></b-tooltip>
              <b-tooltip
                v-else
                :target="'lote' + data.item.id"
                title="Lote Deshabilitado"
              ></b-tooltip>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <AgregarUsuarioModal @nuevoUsuario="getUsers" />
    <EditUsuarioModal :usuario="usuario" @editUsuario="getUsers" />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import AgregarUsuarioModal from "@/components/Modals/AgregarUsuarioModal";
import EditUsuarioModal from "@/components/Modals/EditUsuarioModal";
import { calcularIntervalo } from "@/utils/helpers";

export default {
  name: "Users",
  components: { AgregarUsuarioModal, EditUsuarioModal },
  computed: {
    ...mapGetters("user", ["users", "meta", "links", "loading", "error"]),
  },
  data() {
    return {
      option: null,
      sortBy: "id",
      sortDesc: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      usuario: null,
      interval: null,
      loading: false,
      customFilters: {
        from: null,
        to: null,
        status: [],
        search: null,
        filterType: null,
      },
      optionFilterType: [
        { value: "firstname", text: "Nombre" },
        { value: "email", text: "Email" },
        { value: "dni", text: "Ced/Rif" },
        { value: "created_at", text: "Fecha Registro" },
      ],
      optionsStatus: [
        { item: "lote", name: "Solicitud por Lote" },
        { item: 1, name: "Usuario" },
        { item: 4, name: "Agente/Analista" },
        { item: 5, name: "Supervisor" },
        { item: 7, name: "Empresa" },
        { item: 8, name: "Instituto" },
        { item: 9, name: "Ente Receptor" },
      ],
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "firstname", sortable: true, label: "Nombre" },
        { key: "lastname", sortable: true, label: "Apellido" },
        { key: "cedula", sortable: true, label: "Cedula" },
        { key: "email", sortable: true, label: "Email" },
        { key: "roleName", sortable: true, label: "Role" },
        { key: "lote", sortable: true, label: "Lote" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
      optionInterval: [
        { value: 1, text: "Hoy" },
        { value: 2, text: "Ayer" },
        { value: 3, text: "Últimos 7 dias" },
        { value: 4, text: "Últimos 15 dias" },
        { value: 5, text: "Intervalo de fechas" },
      ],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getUsers() {
      await store.dispatch("user/getUsers", {
        page: this.currentPage,
        filters: this.customFilters,
      });
      this.loading = false;
    },
    async setClick(event, page) {
      event.preventDefault();
      this.loading = true;
      await store.dispatch("user/getUsers", {
        page,
        filters: this.customFilters,
      });
      this.loading = false;
    },
    openModalAgregarUsuario(modal) {
      modal.show("agregarUsuarioModal");
    },
    openModalEditUsuario(modal, usuario) {
      this.usuario = usuario;
      modal.show("editUsuarioModal");
    },
    async eliminarUsuario(usuarioID) {
      if (
        confirm("El registro sera eliminado del sistema, ¿Está de acuerdo?")
      ) {
        await store.dispatch("user/deleteUser", usuarioID);
        await this.getUsers();
      }
    },
    async filtersUsers() {
      this.loading = true;
      await this.getUsers();
    },
    onReset() {
      this.customFilters.from = null;
      this.customFilters.to = null;
      this.customFilters.status = [];
      this.customFilters.search = null;
      this.customFilters.filterType = null;
      this.interval = null;
    },
    setInterval() {
      if (this.interval !== 5) {
        const { from, to } = calcularIntervalo(this.interval);
        this.customFilters.from = from;
        this.customFilters.to = to;
      }
    },
  },
};
</script>
<style>
.icon-table {
  margin: 5px;
}
</style>
