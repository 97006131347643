<template>
  <b-container>
    <b-row>
      <Filtros v-show="is_allowed" @refreshTableFiltros="refreshTableFiltros" />
    </b-row>
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Buscar"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="..."
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col col lg="6" v-if="meta">
        <b-pagination
          v-model="meta.current_page"
          :total-rows="meta.total"
          :per-page="meta.per_page"
          align="fill"
          size="sm"
          class="my-0"
          aria-controls="usersTable"
          @page-click="setClick"
        ></b-pagination>
        <b-row>
          <b-col col lg="4">
            <small>Página actual: {{ meta.current_page }}</small>
          </b-col>
          <b-col col offset-lg="5" lg="3" style="text-align: right">
            <small>Total: {{ meta.total }}</small>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          v-if="formas.length > 0"
          class="formas-table"
          striped
          hover
          sort-icon-left
          :ref="type"
          :items="formas"
          :fields="type === 'forma003' ? fields2 : fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(status)="data">
            <b-icon
              v-if="data.value === 'pendiente'"
              :id="'pendiente' + data.item.id"
              icon="dash-square-fill"
              scale="1.5"
              variant="secondary"
            >
            </b-icon>
            <b-icon
              v-else-if="data.value === 'aprobada'"
              :id="'aprobada' + data.item.id"
              icon="check-square-fill"
              scale="1.5"
              variant="success"
            ></b-icon>
            <b-icon
              v-else-if="data.value === 'rechazada'"
              :id="'rechazada' + data.item.id"
              icon="x-square-fill"
              scale="1.5"
              variant="danger"
            ></b-icon>
            <b-icon
              v-else-if="data.value === 'usada'"
              :id="'usada' + data.item.id"
              icon="check-square-fill"
              scale="1.5"
              variant="info"
            ></b-icon>
            <b-tooltip
              v-if="data.value === 'pendiente'"
              :target="'pendiente' + data.item.id"
              title="Pendiente"
            ></b-tooltip>
            <b-tooltip
              v-else-if="data.value === 'aprobada'"
              :target="'aprobada' + data.item.id"
              title="Aprobada"
            ></b-tooltip>
            <b-tooltip
              v-else-if="data.value === 'rechazada'"
              :target="'rechazada' + data.item.id"
              title="Rechazada"
            ></b-tooltip>
            <b-tooltip
              v-else-if="data.value === 'usada'"
              :target="'usada' + data.item.id"
              title="Usada"
            ></b-tooltip>
          </template>
          <template #cell(opciones)="data">
            <b-link
              href="#"
              v-if="data.item.status === 'pendiente'"
              class="icon-table"
            >
              <b-icon
                :id="'eliminar' + data.item.id"
                icon="trash-fill"
                scale="1.5"
                variant="danger"
                @click="eliminarSolicitud(data.item.id)"
              >
              </b-icon>
              <b-tooltip
                :target="'eliminar' + data.item.id"
                title="Eliminar"
              ></b-tooltip>
            </b-link>
            <b-link v-if="data.item.status === 'aprobada'" class="icon-table">
              <b-icon
                :id="'imprimir' + data.item.id"
                icon="file-earmark-arrow-down-fill"
                scale="1.5"
                variant="info"
                @click="imprimirSolicitud(data.item.id)"
              >
              </b-icon>
              <b-tooltip
                :target="'imprimir' + data.item.id"
                title="Imprimir"
              ></b-tooltip>
            </b-link>
            <b-link
              v-if="
                data.item.status === 'pendiente' &&
                data.item.referencia &&
                is_allowed
              "
              class="icon-table"
            >
              <b-icon
                :id="'status' + data.item.id"
                icon="check-square-fill"
                scale="1.5"
                variant="info"
                @click="openModal($bvModal, data.item.id, 'status')"
              >
              </b-icon>
              <b-tooltip
                :target="'status' + data.item.id"
                title="Cambiar Status"
              ></b-tooltip>
            </b-link>
            <b-link
              v-if="data.item.status === 'pendiente' && !data.item.referencia"
              class="icon-table"
            >
              <b-icon
                :id="'referencia' + data.item.id"
                icon="arrow-up-square-fill"
                scale="1.5"
                variant="secondary"
                @click="openModal($bvModal, data.item.id, 'referencia')"
              >
              </b-icon>
              <b-tooltip
                :target="'referencia' + data.item.id"
                title="Cargar referencia de pago"
              ></b-tooltip>
            </b-link>
            <FormasModal
              :forma="data.item"
              :type="type"
              :option="option"
              :showModal="showModal"
              :id="`formasModal-${type}-${data.item.id}`"
              @refreshTable="refreshTable"
            />
          </template>
        </b-table>
        <b-table
          v-else
          striped
          hover
          :items="noRow"
          :fields="fields"
          sort-icon-left
        ></b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import FormasModal from "@/components/Modals/FormasModal";
import { getError } from "@/utils/helpers";
import Filtros from "@/components/Otros/Filtros";

export default {
  components: {
    FormasModal,
    Filtros,
  },
  props: ["type"],
  data() {
    return {
      option: null,
      sortBy: "id",
      sortDesc: true,
      filter: null,
      showModal: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      meta: null,
      filterOn: [],
      formas: [],
      customFilters: {
        from: null,
        to: null,
        status: ["pendiente"],
        search: null,
        filterType: null,
      },
      fields: [
        { key: "id", sortable: true, label: "Nro" },
        { key: "codigo", sortable: true, label: "Codigo" },
        { key: "firstname", sortable: true, label: "Usuario" },
        { key: "dni", sortable: true, label: "Ced/Rif" },
        { key: "banco", sortable: true, label: "Banco" },
        { key: "monto", sortable: true, label: "Monto" },
        { key: "referencia", sortable: true, label: "Referencia" },
        { key: "fecha", sortable: true, label: "Fecha" },
        { key: "status", sortable: true, label: "Status" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
      fields2: [
        // { key: "id", sortable: true, label: "Nro" },
        { key: "codigo", sortable: true, label: "Codigo" },
        { key: "firstname", sortable: true, label: "Usuario" },
        { key: "dni", sortable: true, label: "Ced/Rif" },
        { key: "banco", sortable: true, label: "Banco" },
        { key: "monto", sortable: true, label: "Monto" },
        { key: "especies", sortable: true, label: "Especies" },
        { key: "estampillas", sortable: true, label: "Estampillas" },
        { key: "otros", sortable: true, label: "Otros" },
        { key: "referencia", sortable: true, label: "Ref" },
        { key: "fecha", sortable: true, label: "Fecha" },
        { key: "status", sortable: true, label: "Status" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
      noRow: [
        {
          id: "No hay registros recientes.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("forma", [
      "loading",
      "error",
      "formas001",
      "formas003",
      "formas005",
      "formas001Meta",
      "formas003Meta",
      "formas005Meta",
      "links",
    ]),
    ...mapGetters("auth", ["authUser", "is_allowed"]),
  },
  async mounted() {
    this.loadData();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async eliminarSolicitud(id) {
      try {
        const type = this.type;
        await store.dispatch("forma/eliminarSolicitud", { id, type });
        if (this.type === "forma001") {
          this.formas = this.formas001;
        } else if (this.type === "forma003") {
          this.formas = this.formas003;
        } else if (this.type === "forma005") {
          this.formas = this.formas005;
        }
      } catch (error) {
        this.error = getError(error);
      }
    },
    async imprimirSolicitud(id) {
      const type = this.type;
      try {
        await store.dispatch("forma/getRecibo", { type, id });
      } catch (error) {
        this.error = getError(error);
      }
    },
    openModal(modal, id, option) {
      this.option = option;
      modal.show(`forma-${this.type}-${id}`);
    },
    async loadData() {
      try {
        const page = 1;
        const type = this.type;
        await store.dispatch("forma/getFormas", {
          page,
          type,
          filters: this.customFilters,
        });
        if (this.type === "forma001") {
          this.formas = this.formas001;
          this.meta = this.formas001Meta;
        } else if (this.type === "forma003") {
          this.formas = this.formas003;
          this.meta = this.formas003Meta;
        } else if (this.type === "forma005") {
          this.formas = this.formas005;
          this.meta = this.formas005Meta;
        }
      } catch (err) {
        this.error = getError(err);
      }
    },
    refreshTable() {
      this.loadData();
    },
    async setClick(event, page) {
      event.preventDefault();
      const type = this.type;
      await store.dispatch("forma/getFormas", {
        page,
        type,
        filters: this.customFilters,
      });
      if (this.type === "forma001") {
        this.formas = this.formas001;
        this.meta = this.formas001Meta;
      } else if (this.type === "forma003") {
        this.formas = this.formas003;
        this.meta = this.formas003Meta;
      } else if (this.type === "forma005") {
        this.formas = this.formas005;
        this.meta = this.formas005Meta;
      }
    },
    refreshTableFiltros(customFilters) {
      this.customFilters = customFilters;
      this.loadData();
    },
  },
};
</script>

<style>
.formas-table {
  font-size: 0.8em;
}
</style>
