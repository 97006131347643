<template>
  <b-container>
    <b-modal
      id="agregarCienteModal"
      ref="agregarCienteModal"
      name="agregarCienteModal"
      size="xl"
      centered
      hide-footer
      title="Registrar nuevo cliente"
    >
      <b-form @submit.prevent="registrarCliente">
        <b-row>
          <b-col>
            <b-form-group label="Empresa" label-for="empresa">
              <b-form-input
                type="text"
                required
                v-model="empresa"
                name="empresa"
                autocomplete="empresa"
                autofocus="autofocus"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Rif" label-for="rif">
              <b-form-input
                type="text"
                v-model="rif"
                name="empresa"
                autocomplete="rif"
                placeholder="JXXXXYYY"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Placa" label-for="placa">
              <b-form-input
                type="text"
                required
                v-model="placa"
                name="placa"
                autocomplete="placa"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Chofer" label-for="chofer">
              <b-form-input
                type="text"
                v-model="chofer"
                name="chofer"
                autocomplete="chofer"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedúla del Chofer" label-for="cedula">
              <b-form-input
                type="text"
                v-model="cedula"
                name="cedula"
                autocomplete="cedula"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/index";

export default {
  data() {
    return {
      empresa: null,
      chofer: null,
      rif: null,
      cedula: null,
      placa: null,
    };
  },
  computed: {},
  methods: {
    async registrarCliente() {
      if (this.placa || this.empresa) {
        const payload = {
          empresa: this.empresa,
          chofer: this.chofer,
          rif: this.rif,
          cedula: this.cedula,
          placa: this.placa,
        };

        await store.dispatch("cliente/crearCliente", payload);

        this.$emit("nuevoCliente", payload);
        this.$nextTick(() => {
          this.$bvModal.hide("agregarCienteModal");
        });
        await store.dispatch("cliente/getClientes");
      }
    },
  },
};
</script>

<style></style>
