<template>
  <b-container>
    <b-modal
      id="editUteModal"
      ref="editUteModal"
      name="editUteModal"
      size="xl"
      centered
      hide-footer
      title="Modificar UCD"
    >
      <b-form @submit.prevent="modificarUte">
        <b-row>
          <b-col col lg="6">
            <b-form-group label="Periodo" label-for="periodo">
              <b-form-input
                id="periodo"
                type="text"
                v-model="periodo"
                autocomplete="periodo"
                name="periodo"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="2">
            <b-form-group label="Valor" label-for="valor">
              <b-form-input
                id="valor"
                step="0.01"
                type="number"
                v-model="valor"
                autocomplete="valor"
                name="valor"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/index";

export default {
  props: ["ute"],
  data() {
    return {
      periodo: null,
      valor: null,
      id: null,
    };
  },
  mounted() {
    this.$root.$on("bv::modal::shown", () => {
      if (this.ute) {
        this.id = this.ute.id;
        this.periodo = this.ute.periodo;
        this.valor = this.ute.valor;
      }
    });
  },
  computed: {},
  methods: {
    async modificarUte() {
      try {
        const payload = {
          id: this.id,
          periodo: this.periodo,
          valor: this.valor,
        };
        await store.dispatch("definiciones/updateUte", payload);
        this.$emit("editUte", payload);
        this.$nextTick(() => {
          this.$bvModal.hide("editUteModal");
          this.resetForm();
        });
      } catch (error) {
        console.error(error);
      }
    },
    resetForm() {
      this.id = null;
      this.periodo = null;
      this.valor = null;
    },
  },
};
</script>

<style></style>
