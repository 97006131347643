<template>
  <b-container>
    <b-form @submit.prevent="solicitarEstampilla">
      <b-container fluid class="estampilla-from">
        <b-row>
          <b-col cols="4"><h5>Perfil de Usuario</h5></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Usuario" label-for="user">
              <b-form-input
                id="user"
                type="text"
                required
                v-model="user"
                autocomplete="user"
                name="user"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedula / RIF" label-for="dni">
              <b-form-input
                id="dni"
                type="text"
                v-model="dni"
                autocomplete="dni"
                name="dni"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Clave Actual" label-for="oldPassword">
              <b-form-input
                type="password"
                required
                v-model="oldPassword"
                name="oldPassword"
                class="register-input"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Nueva Clave" label-for="newPassword">
              <b-form-input
                type="password"
                required
                v-model="newPassword"
                name="newPassword"
                class="register-input"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Confirme Clave" label-for="password-confirm">
              <b-form-input
                type="password"
                required
                v-model="passwordConfirm"
                name="password-confirm"
                class="register-input"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-container>
</template>

<script>
import { getError, capitalize } from "@/utils/helpers";
import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  data() {
    return {
      user: null,
      dni: null,
      password: null,
      passwordConfirm: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("definiciones", ["ut", "cuentas", "tipoDocumento"]),
    optionCuentas: function () {
      const docs = this.cuentas;
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
    optionTipoDocumento: function () {
      const docs = this.tipoDocumento;
      return docs.map((x) => {
        return {
          value: x.id,
          text: x.nombre,
        };
      });
    },
  },
  methods: {
    async solicitarEstampilla() {
      try {
        const payload = {
          cuentas_id: this.cuenta,
          denominacion: this.denominacion,
          monto: this.monto,
          tipos_documentos_id: this.tipoDocumentoSelected,
          unidad_tributaria_id: this.ut.id,
        };
        await store.dispatch("estampilla/solicitarEstampilla", payload);
        this.$router.push({ path: "/estampillas/estampillas" });
      } catch (error) {
        this.error = getError(error);
      }
    },
    parseCuentas: function (docs) {
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
  },
  watch: {
    denominacion: function (value) {
      const monto = value * this.ut.valor;
      this.monto = monto.toFixed(2);
    },
  },
  mounted() {
    this.user = `${capitalize(this.authUser.firstname)} ${capitalize(
      this.authUser.lastname
    )}`;
    this.dni = `${this.authUser.tipoPersona}-${this.authUser.dni}`;
  },
};
</script>
5
