import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Toast from "vue-toastification";
import moment from "moment-timezone";

import "@/assets/css/bootstrap/bootstrap.min.css";
import "vue-toastification/dist/index.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.min.css";

import "@/assets/css/themify-icons.css";
import "@/assets/css/style.css";

moment.tz.setDefault("America/Caracas");

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast);

Vue.config.productionTip = true;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
