<template>
  <b-container>
    <b-container fluid class="estampilla-from">
      <b-row>
        <b-col cols="4"
          ><h2 v-if="this.$route.params.type === 'D'">
            Publicaciones Destacadas
          </h2>
          <h2 v-if="this.$route.params.type === 'N'">Noticias</h2>
          <h2 v-if="this.$route.params.type === 'G'">Gacetas</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col col lg="6" class="my-1">
          <b-form-group
            label="Buscar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col col lg="6" v-if="meta">
          <b-pagination
            v-model="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            align="fill"
            size="sm"
            class="my-0"
            aria-controls="releaseTable"
            @page-click="setClick"
          ></b-pagination>
          <b-row>
            <b-col col lg="4">
              <small>Página actual: {{ meta.current_page }}</small>
            </b-col>
            <b-col col offset-lg="5" lg="3" style="text-align: right">
              <small>Total: {{ meta.total }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="9">
          <b-table
            striped
            hover
            sort-icon-left
            :items="release"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            id="releaseTable"
          >
            <template #cell(contenido)="data">
              {{ data.item.contenido.substr(0, 200) + "..." }}
            </template>

            <template #cell(imagen)="data">
              <b-img
                :src="ruta + data.item.imagen"
                width="100"
                height="100"
              ></b-img>
            </template>

            <template #cell(acciones)="data">
              <a
                v-if="data.item.publicacion === 'N'"
                :href="'../noticias/' + data.item.id"
              >
                Leer
              </a>
              <a v-else :href="'../gacetas/' + data.item.id"> Leer </a>
            </template>
          </b-table>
        </b-col>
        <b-col>
          <Categorias />
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import Categorias from "@/components/CategoriasWidget.vue";
export default {
  components: { Categorias },
  computed: {
    ...mapGetters("publicacion", [
      "release",
      "meta",
      "links",
      "loading",
      "error",
    ]),
  },
  data() {
    return {
      option: null,
      sortBy: "titulo",
      sortDesc: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      usuario: null,
      fields: [
        { key: "titulo", sortable: false, label: "Titulo" },
        { key: "contenido", sortable: false, label: "Contenido" },
        { key: "imagen", sortable: false, label: "Imagen" },
        { key: "acciones", sortable: false, label: "     " },
      ],
      route: this.$route.params.type,
      ruta: process.env.VUE_APP_API_URL + "/publicaciones/",
    };
  },
  mounted() {
    this.getRelease();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getRelease() {
      if (this.route === "D") {
        await store.dispatch(
          "publicacion/paginatedDestacadas",
          this.currentPage
        );
      } else if (this.route === "N") {
        await store.dispatch("publicacion/paginatedRelease", this.currentPage);
      } else if (this.route === "G") {
        await store.dispatch(
          "publicacion/paginatedPublications",
          this.currentPage
        );
      }
    },
    async setClick(event, page) {
      event.preventDefault();
      if (this.route === "D") {
        await store.dispatch("publicacion/paginatedDestacadas", page);
      } else if (this.route === "N") {
        await store.dispatch("publicacion/paginatedRelease", page);
      } else if (this.route === "G") {
        await store.dispatch("publicacion/paginatedPublications", page);
      }
    },
  },
};
</script>
