<template>
  <div>
    <div class="card mb-4">
      <div class="card-header">Categorias</div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <ul class="list-unstyled mb-0">
              <li>
                <small>
                  <a :href="'/../publicaciones/' + 'D'" class="text-primary"
                    >Destacad@s</a
                  >
                </small>
              </li>
              <li>
                <small>
                  <a :href="'/../publicaciones/' + 'N'" class="text-primary"
                    >Noticias</a
                  >
                </small>
              </li>
              <li>
                <small>
                  <a :href="'/../publicaciones/' + 'G'" class="text-primary"
                    >Gacetas</a
                  >
                </small>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "categoriasWidget",
};
</script>

<style lang="scss" scoped></style>
