<template>
  <b-container>
    <b-container fluid class="estampilla-from">
      <b-row>
        <b-col cols="4"><h5>Solicitudes recientes</h5></b-col>
      </b-row>
      <b-row>
        <Filtros @refreshTableFiltros="refreshTableFiltros" />
      </b-row>
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Buscar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col col lg="6" v-if="loteMeta">
          <b-pagination
            v-model="loteMeta.current_page"
            :total-rows="loteMeta.total"
            :per-page="loteMeta.per_page"
            align="fill"
            size="sm"
            class="my-0"
            aria-controls="usersTable"
            @page-click="setClick"
          ></b-pagination>
          <b-row>
            <b-col col lg="4">
              <small>Página actual: {{ loteMeta.current_page }}</small>
            </b-col>
            <b-col col offset-lg="5" lg="3" style="text-align: right">
              <small>Total: {{ loteMeta.total }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            class="lotes-table"
            v-if="estampillas.length > 0"
            striped
            hover
            sort-icon-left
            :items="estampillas"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(denominacion)="data">
              {{ data.value.toFixed(2) }}
            </template>
            <template #cell(monto)="data">
              {{ data.value.toFixed(2) }}
            </template>
            <template #cell(status)="data">
              <!-- pendiente -->
              <b-icon
                v-if="data.value === 'pendiente'"
                :id="'pendiente' + data.item.id"
                icon="dash-square-fill"
                scale="1.5"
                variant="secondary"
              >
              </b-icon>
              <b-tooltip
                v-if="data.value === 'pendiente'"
                :target="'pendiente' + data.item.id"
                title="Pendiente"
              ></b-tooltip>
              <!-- aprobada -->
              <b-icon
                v-else-if="data.value === 'aprobada'"
                :id="'aprobada' + data.item.id"
                icon="check-square-fill"
                scale="1.5"
                variant="success"
              ></b-icon>
              <b-tooltip
                v-if="data.value === 'aprobada'"
                :target="'aprobada' + data.item.id"
                title="Aprobada"
              ></b-tooltip>
              <!-- rechazada -->
              <b-icon
                v-else-if="data.value === 'rechazada'"
                :id="'rechazada' + data.item.id"
                icon="x-square-fill"
                scale="1.5"
                variant="danger"
              ></b-icon>
              <b-tooltip
                v-if="data.value === 'rechazada'"
                :target="'rechazada' + data.item.id"
                title="Rechazada"
              ></b-tooltip>
              <!-- usada -->
              <b-icon
                v-else-if="data.value === 'usada'"
                :id="'usada' + data.item.id"
                icon="check-square-fill"
                scale="1.5"
                variant="info"
              ></b-icon>
              <b-tooltip
                v-if="data.value === 'usada'"
                :target="'usada' + data.item.id"
                title="Usada"
              ></b-tooltip>
            </template>
            <template #cell(opciones)="data">
              <!--Eliminar-->
              <b-link
                href="#"
                v-if="
                  data.item.status === 'pendiente' &&
                  data.item.userID === authUser.id
                "
                class="icon-table"
              >
                <b-icon
                  :id="'eliminar' + data.item.id"
                  icon="trash-fill"
                  scale="1.5"
                  variant="danger"
                  @click="eliminarSolicitud(data.item.id)"
                >
                </b-icon>
                <b-tooltip
                  :target="'eliminar' + data.item.id"
                  title="Eliminar"
                ></b-tooltip>
              </b-link>
              <!--Imprimir-->
              <b-link
                v-if="
                  data.item.status === 'aprobada' &&
                  data.item.userID === authUser.id &&
                  data.item.disponibilidad > 0
                "
                class="icon-table"
              >
                <b-icon
                  :id="'imprimir' + data.item.id"
                  icon="file-earmark-arrow-down-fill"
                  scale="1.5"
                  variant="info"
                  @click="openFormModal($bvModal, data.item)"
                >
                </b-icon>
                <b-tooltip
                  :target="'imprimir' + data.item.id"
                  title="Imprimir"
                ></b-tooltip>
              </b-link>
              <!--Cambio de status-->
              <b-link
                v-if="
                  data.item.status === 'pendiente' &&
                  data.item.referencia &&
                  is_allowed
                "
                class="icon-table"
              >
                <b-icon
                  :id="'status' + data.item.id"
                  icon="check-square-fill"
                  scale="1.5"
                  variant="info"
                  @click="openModal($bvModal, data.item.id, 'status')"
                >
                </b-icon>
                <b-tooltip
                  :target="'status' + data.item.id"
                  title="Cambiar status"
                ></b-tooltip>
              </b-link>
              <!--Referencia-->
              <b-link
                v-if="data.item.status === 'pendiente' && !data.item.referencia"
                class="icon-table"
              >
                <b-icon
                  :id="'referencia' + data.item.id"
                  icon="arrow-up-square-fill"
                  scale="1.5"
                  variant="secondary"
                  @click="openModal($bvModal, data.item.id, 'referencia')"
                >
                </b-icon>
                <b-tooltip
                  :target="'referencia' + data.item.id"
                  title="Cargar referencia de pago"
                ></b-tooltip>
              </b-link>
              <LotesModal
                :lote="data.item"
                :option="option"
                :id="`loteModal-${data.item.id}`"
                @refreshTable="refreshTable"
              />
            </template>
          </b-table>
          <b-table
            v-else
            striped
            hover
            :items="noRow"
            :fields="fields"
            sort-icon-left
          ></b-table>
        </b-col>
      </b-row>
    </b-container>
    <LoteFormModal
      :user="authUser"
      :lote="lote"
      :hightRole="is_heightRole"
      @refreshTable="refreshTable"
    />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import LotesModal from "@/components/Modals/LotesModal";
import LoteFormModal from "@/components/Modals/LoteFormModal";
import Filtros from "@/components/Otros/Filtros";
import { getError } from "@/utils/helpers";

export default {
  components: {
    LotesModal,
    LoteFormModal,
    Filtros,
  },
  data() {
    return {
      lote: {
        catidad: null,
      },
      option: null,
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      filter: null,
      filterOn: [],
      filters: {
        from: null,
        to: null,
        status: ["pendiente"],
        search: null,
        filterType: null,
      },
      fields: [
        { key: "id", sortable: true, label: "Nro" },
        { key: "firstname", sortable: true, label: "Usuario" },
        { key: "dni", sortable: true, label: "Ced/Rif" },
        { key: "cantidad", sortable: true, label: "Cantidad" },
        { key: "disponibilidad", sortable: true, label: "Disponibilidad" },
        { key: "denominacion", sortable: true, label: "Denominacion" },
        { key: "monto", sortable: true, label: "Monto" },
        { key: "referencia", sortable: true, label: "Referencia" },
        { key: "fecha", sortable: true, label: "Fecha" },
        { key: "status", sortable: true, label: "Status" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
      noRow: [
        {
          Nro: "No hay registros recientes.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("estampilla", [
      "loading",
      "error",
      "estampillas",
      "loteMeta",
    ]),
    ...mapGetters("auth", ["authUser", "is_allowed", "is_heightRole"]),
  },
  beforeRouteEnter(to, from, next) {
    const page = parseInt(to.query.page) || 1;
    const filters = {
      from: null,
      to: null,
      status: ["pendiente"],
      search: null,
      filterType: null,
    };
    store
      .dispatch("estampilla/getLotes", {
        page,
        filters,
      })
      .then(() => {
        to.params.page = page;
        next();
      });
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async eliminarSolicitud(id) {
      try {
        await store.dispatch("estampilla/eliminarLote", id);
      } catch (error) {
        this.error = getError(error);
      }
    },
    openFormModal(modal, lote) {
      this.lote = lote;
      modal.show(`LoteFormModal`);
    },
    openModal(modal, id, option) {
      this.option = option;
      modal.show(`lote-${id}`);
    },
    async loadData() {
      try {
        const page = 1;
        await store.dispatch("estampilla/getLotes", {
          page,
          filters: this.customFilters,
        });
      } catch (err) {
        this.error = getError(err);
      }
    },
    refreshTable() {
      this.loadData();
    },
    async setClick(event, page) {
      event.preventDefault();
      await store.dispatch("estampilla/getLotes", {
        page,
        filters: this.customFilters,
      });
    },
    refreshTableFiltros(customFilters) {
      this.customFilters = customFilters;
      this.loadData();
    },
  },
};
</script>

<style>
.lotes-table {
  font-size: 0.8em;
}
</style>
