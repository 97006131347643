<template>
  <b-container>
    <br />
    <b-row>
      <b-col cols="4"><h5>Sanciones Registradas</h5></b-col>
    </b-row>
    <br />
    <b-row>
      <b-col col lg="4">
        <b-button
          block
          variant="primary"
          @click="openModalAgregarImpuestoSancion($bvModal)"
          >Nuevo Impuesto / Sanción
        </b-button>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col col lg="6" class="my-1">
        <b-form-group
          label="Buscar"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col col lg="6" v-if="impuestoSancionMeta">
        <b-pagination
          v-model="impuestoSancionMeta.current_page"
          :total-rows="impuestoSancionMeta.total"
          :per-page="impuestoSancionMeta.per_page"
          align="fill"
          size="sm"
          class="my-0"
          aria-controls="impuestosSancionesTable"
          @page-click="setClick"
        ></b-pagination>
        <b-row>
          <b-col col lg="4">
            <small>Current Page: {{ impuestoSancionMeta.current_page }}</small>
          </b-col>
          <b-col col offset-lg="5" lg="3" style="text-align: right">
            <small>Total: {{ impuestoSancionMeta.total }}</small>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          id="impuestosSancionesTable"
          ref="impuestosSancionesTable"
          striped
          hover
          sort-icon-left
          :items="impuestosSanciones"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          small
        >
          <template #cell(opciones)="data">
            <b-link href="#" class="icon-table">
              <b-icon
                :id="'update' + data.item.id"
                icon="pencil-fill"
                scale="1"
                variant="secondary"
                @click="openModalEditImpuestoSancion($bvModal, data.item)"
              >
              </b-icon>
              <b-tooltip
                :target="'update' + data.item.id"
                title="Modificar"
              ></b-tooltip>
            </b-link>
            <b-link href="#" class="icon-table">
              <b-icon
                :id="'eliminar' + data.item.id"
                icon="x-circle-fill"
                scale="1"
                variant="danger"
                @click="eliminarSancionImpuesto(data.item.id)"
              >
              </b-icon>
              <b-tooltip
                :target="'eliminar' + data.item.id"
                title="Eliminar"
              ></b-tooltip>
            </b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <AgregarImpuestoSancionModal
      @nuevoImpuestoSancion="getImpuestosSanciones"
    />
    <EditImpuestoSancionModal
      v-if="impuestoSancion"
      @editImpuestoSancion="getImpuestosSanciones"
      :impuestoSancion="impuestoSancion"
    />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import AgregarImpuestoSancionModal from "@/components/Modals/AgregarImpuestoSancionModal";
import EditImpuestoSancionModal from "@/components/Modals/EditImpuestoSancionModal";

export default {
  components: { AgregarImpuestoSancionModal, EditImpuestoSancionModal },
  data() {
    return {
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      impuestoSancion: null,
      fields: [
        { key: "codigo", sortable: true, label: "Codigo" },
        { key: "ley", sortable: true, label: "Ley" },
        { key: "concepto", sortable: true, label: "Descripción" },
        { key: "articulo", sortable: true, label: "Art." },
        { key: "numeral", sortable: false, label: "Num." },
        { key: "cantidad_ut", sortable: true, label: "UT" },
        { key: "tipo", sortable: true, label: "Tipo" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("definiciones", [
      "impuestosSanciones",
      "impuestoSancionMeta",
    ]),
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("definiciones/getImpuestosSanciones");
    next();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getImpuestosSanciones() {
      await store.dispatch(
        "definiciones/getImpuestosSanciones",
        this.currentPage
      );
    },
    openModalAgregarImpuestoSancion(modal) {
      modal.show("agregarImpuestoSancionModal");
    },
    openModalEditImpuestoSancion(modal, impuestoSancion) {
      this.impuestoSancion = impuestoSancion;
      modal.show("editImpuestoSancionModal");
    },
    async eliminarSancionImpuesto(impuestoSancionID) {
      if (
        confirm("El registro sera eliminado del sistema, ¿Está de acuerdo?")
      ) {
        await store.dispatch(
          "definiciones/deleteImpuestoSancion",
          impuestoSancionID
        );
        await this.getImpuestosSanciones();
      }
    },
    async setClick(event, page) {
      event.preventDefault();
      await store.dispatch("definiciones/getImpuestosSanciones", page);
    },
  },
};
</script>

<style></style>
