<template>
  <div class="login-container">
    <Spinner v-if="loading" />
    <div class="shapes-container">
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="1000"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="300"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="zoom-out"
        data-aos-duration="2000"
        data-aos-delay="500"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="200"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-down"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
      <div
        class="shape"
        data-aos="fade-up-right"
        data-aos-duration="500"
        data-aos-delay="100"
      ></div>
      <div
        class="shape"
        data-aos="fade-down-left"
        data-aos-duration="500"
        data-aos-delay="0"
      ></div>
    </div>
    <section>
      <b-container>
        <div class="block login-marco">
          <div class="image align-self-center login-a">
            <b-img
              :src="require('@/assets/img/background/tbolivar-edeficio.jpg')"
              alt="login-img"
              class="login-img"
            ></b-img>
          </div>
          <div class="content text-center login-background">
            <div class="logo">
              <b-link href="/">
                <b-img
                  :src="require('@/assets/img/logos/logo-01.png')"
                  alt="logo-tributos"
                ></b-img>
              </b-link>
            </div>
            <br />
            <div class="title-text">
              <h3>Ingrese a su cuenta</h3>
            </div>
            <br />
            <br />
            <b-form @submit.prevent="login">
              <b-form-input
                type="email"
                placeholder="Correo"
                required
                v-model="email"
                autocomplete="email"
                name="email"
              ></b-form-input>
              <b-form-input
                type="password"
                placeholder="Clave"
                required
                v-model="password"
                name="password"
              ></b-form-input>
              <br />
              <vue-hcaptcha
                sitekey="5d892cfe-fb01-4d7e-bf42-c664bd21411a"
                @verify="verify"
                language="es"
              ></vue-hcaptcha>
              <br />
              <div>
                <b-button type="submit" block variant="primary">
                  Iniciar Sesion
                </b-button>
              </div>
            </b-form>
            <br />
            <div class="new-acount">
              <b-link href="/forgot-password">Olvido su clave?</b-link>
              <p>
                No tiene una cuenta?
                <b-link href="/register">Registrarse</b-link>
              </p>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>

<script>
import Spinner from "@/components/Otros/Spinner.vue";
import AuthService from "@/services/AuthService";
import { getError } from "@/utils/helpers";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  name: "Login",
  components: {
    Spinner,
    VueHcaptcha,
  },
  data() {
    return {
      email: null,
      password: null,
      loading: false,
      captcha: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      const payload = {
        email: this.email,
        password: this.password,
      };
      this.error = null;
      try {
        this.captcha = true;
        if (this.captcha) {
          await AuthService.login(payload);
          const authUser = await this.$store.dispatch("auth/getAuthUser");
          this.loading = false;
          if (authUser) {
            await this.$store.dispatch("auth/setGuest", {
              value: "isNotGuest",
            });
            this.$router.push("/dashboard");
          } else {
            const error = Error(
              "No se pueden obtener la informacion del usuario."
            );
            error.name = "Fetch User";
            throw error;
          }
        }
      } catch (error) {
        this.loading = false;
        const { email } = error.response.data.errors;
        if (email) {
          this.$toast.error(email[0], { timeout: 3000 });
        } else {
          this.$toast.error(getError(error), { timeout: 3000 });
        }
      }
    },
    verify() {
      this.captcha = true;
    },
  },
};
</script>
<style></style>
