import * as API from "@/services/API";

export default {
  /*generarEstampilla(id) {
    return API.apiClient.get(`/generar/${id}`);
  },*/
  getFormas({ page, type, filters }) {
    return API.apiClient.get(`/formas/${type}/?page=${page}`, {
      params: {
        ...filters,
      },
    });
  },
  getRecibo(payload) {
    return API.apiClient.post(`/formas/getRecibo`, payload, {
      responseType: "arraybuffer",
    });
  },
  solicitarForma(payload) {
    return API.apiClient.post("/formas/solicitud", payload);
  },
  setReferenciaPago(payload) {
    return API.apiClient.post("/formas/setReferencia", payload);
  },
  setStatusSolicitud(payload) {
    return API.apiClient.post("/formas/setStatusSolicitud", payload);
  },
  eliminarSolicitud({ id, type }) {
    return API.apiClient.get(`/formas/eliminar/${type}/${id}`);
  },
  verificar(payload) {
    return API.apiClient.post("/formas/verificar", payload);
  },
  usarForma(payload) {
    return API.apiClient.post("/formas/usarForma", payload);
  },
  /*paginateEstampillas(link) {
    return API.apiClient.get(link);
  },
 */
};
