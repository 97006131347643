<template>
  <b-container>
    <b-form @submit.prevent="solicitarForma003">
      <b-container fluid class="estampilla-from">
        <b-row>
          <b-col cols="4"><h5>Solicitud de Forma 003</h5></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Usuario" label-for="user">
              <b-form-input
                id="user"
                type="text"
                required
                v-model="user"
                autocomplete="user"
                name="user"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedula / RIF" label-for="dni">
              <b-form-input
                id="dni"
                type="text"
                v-model="dni"
                autocomplete="dni"
                name="dni"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Forma de Pago" label-for="formaPago">
              <b-form-select
                id="formaPago"
                v-model="formaPago"
                :options="optionFormaPago"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Banco" label-for="banco">
              <b-form-select
                id="banco"
                v-model="cuenta"
                :options="optionCuentas"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Por Venta de Especies Fiscales"
              label-for="especies"
            >
              <b-form-input
                type="number"
                v-model="especies"
                autocomplete="especies"
                name="especies"
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Multas" label-for="multas">
              <b-form-input
                type="number"
                v-model="multas"
                autocomplete="multas"
                name="multas"
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Estampillas Fiscales" label-for="estampillas">
              <b-form-input
                type="number"
                v-model="estampillas"
                autocomplete="estampillas"
                name="estampillas"
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Otros" label-for="otros">
              <b-form-input
                type="number"
                v-model="otros"
                autocomplete="otros"
                name="otros"
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { capitalize } from "@/utils/helpers.js";
import { getError } from "@/utils/helpers";
import store from "@/store/index";

export default {
  components: {},
  data() {
    return {
      error: null,
      user: null,
      dni: null,
      cuenta: null,
      formaPago: null,
      monto: null,
      especies: null,
      multas: null,
      estampillas: null,
      otros: null,
      optionLabel: null,
      customActividad: null,
      optionFormaPago: [
        { value: "transferencia", text: "Transferencia" },
        // { value: "pago_movil", text: "Pago Móvil" },
      ],
    };
  },
  mounted() {
    this.user = `${capitalize(this.authUser.firstname)} ${capitalize(
      this.authUser.lastname
    )}`;
    this.dni = `${this.authUser.tipoPersona}-${this.authUser.dni}`;
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("definiciones", ["cuentas"]),
    optionCuentas: function () {
      const docs = this.cuentas;
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
  },
  methods: {
    async solicitarForma003() {
      try {
        const payload = {
          cuentas_id: this.cuenta,
          forma_pago: this.formaPago,
          monto: this.multas,
          especies: this.especies,
          estampillas: this.estampillas,
          otros: this.otros,
          type: "forma003",
        };
        await store.dispatch("forma/solicitarForma", payload);
        //this.$router.push({ path: "/impuestos/formas" });
      } catch (error) {
        this.error = getError(error);
      }
    },
  },
};
</script>

<style></style>
