<template>
  <b-container class="marco">
    <b-row>
      <b-col cols="4"><h5>Historico de Operaciones</h5></b-col>
    </b-row>
    <br />
    <b-form @submit.prevent="getReport" @reset.prevent="onReset">
      <b-row>
        <b-col>
          <b-form-group label="Tipo de Operación" label-for="operationType">
            <b-form-select
              id="operationType"
              v-model="operationType"
              :options="optionOperationType"
              required
              class="form-control"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Intervalo" label-for="interval">
            <b-form-select
              id="interval"
              v-model="interval"
              :options="optionInterval"
              required
              class="form-control"
              @change="setInterval"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="interval === 5">
          <b-form-group label="Desde" label-for="from">
            <b-form-datepicker
              id="from"
              v-model="from"
              class="form-control"
              locale="es"
              label-help=""
              label-no-date-selected="Elija Fecha"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col v-if="interval === 5">
          <b-form-group label="Hasta" label-for="to">
            <b-form-datepicker
              id="to"
              v-model="to"
              class="form-control"
              locale="es"
              label-help=""
              label-no-date-selected="Elija Fecha"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="report-buttons">
          <b-button
            type="submit"
            variant="primary"
            v-on:click="isGetted = true"
          >
            Buscar
          </b-button>
        </b-col>
        <b-col cols="1" class="report-buttons">
          <b-button type="reset" variant="secondary"> Limpiar </b-button>
        </b-col>
      </b-row>
      <br />
    </b-form>
    <br />
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Buscar"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Limpiar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col col lg="6" v-if="isGetted">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
          aria-controls="operationsTable"
        ></b-pagination>
        <b-row>
          <b-col col lg="4">
            <small>Página actual: {{ currentPage }}</small>
          </b-col>
          <b-col col offset-lg="5" lg="3" style="text-align: right">
            <small>Total: {{ rows }}</small>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="10">
        <b-table
          striped
          hover
          sort-icon-left
          :items="operations"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :per-page="perPage"
          :current-page="currentPage"
          v-if="isGetted"
          id="operationsTable"
        >
          <template #cell(old_values)="data">
            <p
              v-for="(value, key) in JSON.parse(data.item.old_values)"
              :key="key"
            >
              {{ key }}: {{ value }}
            </p>
          </template>

          <template #cell(new_values)="data">
            <p
              v-for="(value, key) in JSON.parse(data.item.new_values)"
              :key="key"
            >
              {{ key }}: {{ value }}
            </p>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
import { calcularIntervalo } from "@/utils/helpers";

export default {
  data() {
    return {
      operationType: null,
      interval: null,
      from: null,
      to: null,
      filter: null,
      isGetted: false,
      perPage: 3,
      currentPage: 1,
      optionOperationType: [
        { value: "created", text: "Creacion" },
        { value: "updated", text: "Actualización" },
        { value: "Logged in", text: "Inicio de sesión" },
        { value: "deleted", text: "Eliminación" },
      ],
      optionInterval: [
        { value: 1, text: "Hoy" },
        { value: 2, text: "Ayer" },
        { value: 3, text: "Últimos 7 dias" },
        { value: 4, text: "Últimos 15 dias" },
        { value: 5, text: "Intervalo de fechas" },
      ],
      fields: [
        { key: "user_id", sortable: true, label: "Usuario" },
        { key: "auditable_type", sortable: true, label: "Log" },
        { key: "old_values", sortable: true, label: "Valores anteriores" },
        { key: "new_values", sortable: true, label: "Valores nuevos" },
        { key: "url", sortable: true, label: "Url" },
        { key: "created_at", sortable: true, label: "Fecha de ejecución" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("operacion", ["operations", "loading", "meta", "links"]),
    rows() {
      return this.operations.length;
    },
  },
  methods: {
    async getReport() {
      const payload = {
        operationType: this.operationType,
        from: this.from,
        to: this.to,
      };
      await store.dispatch("operacion/getOperations", payload);
    },
    onReset() {
      this.operationType = null;
      this.interval = null;
      this.from = null;
      this.to = null;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    setInterval() {
      if (this.interval !== 5) {
        const { from, to } = calcularIntervalo(this.interval);
        this.from = from;
        this.to = to;
      }
    },
  },
};
</script>

<style>
.marco {
  margin-top: 1.5em !important;
}
.status div {
  margin-left: 5px !important;
  margin-right: 15px !important;
  display: inline !important;
}
.status .custom-control-input {
  margin-right: 5px !important;
}
.report-buttons {
  padding-top: 25px !important;
}
</style>
