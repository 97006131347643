import * as API from "@/services/API";

export default {
  getUser(userId) {
    return API.apiClient.get(`/users/${userId}`);
  },
  getUsers(page, filters) {
    return API.apiClient.get(`/users/?page=${page}`, {
      params: {
        ...filters,
      },
    });
  },
  paginateUsers(link) {
    return API.apiClient.get(link);
  },
  crearUser(payload) {
    return API.apiClient.post("/users/crearUser", payload);
  },
  updateUser(payload) {
    return API.apiClient.post("/users/updateUser", payload);
  },
  deleteUser(id) {
    return API.apiClient.get(`/users/deleteUser/${id}`);
  },
};
