<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12"></b-col>
      </b-row>
      <Charts v-if="is_allowed"></Charts>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Charts from "@/components/Charts.vue";
import store from "@/store";

export default {
  name: "Dashboard",
  components: { Charts },
  computed: {
    ...mapGetters("auth", ["authUser", "is_allowed"]),
    ...mapGetters("report", [
      "estampillasData",
      "formasData",
      "estampillaSumary",
      "formasSumary",
    ]),
  },
  async beforeRouteEnter(to, from, next) {
    if ([1, 7, 8].includes(store.getters["auth/role"])) {
      next({ name: "Estampillas" });
    } else {
      await store.dispatch("report/getChartData");
      next();
    }
  },
};
</script>
