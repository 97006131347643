<template>
  <b-container>
    <b-modal
      :id="estampillaID"
      :ref="estampillaID"
      :name="estampillaID"
      centered
      hide-footer
      :title="modalTitle"
    >
      <b-form @submit.prevent="updateEstampilla">
        <b-row>
          <b-col> Nro: {{ estampilla.id }} </b-col>
        </b-row>
        <b-row>
          <b-col> Banco: {{ estampilla.banco }} </b-col>
        </b-row>
        <b-row>
          <b-col> Monto: {{ estampilla.monto }} </b-col>
        </b-row>
        <b-row>
          <b-col> Fecha: {{ estampilla.fecha }} </b-col>
        </b-row>
        <b-row>
          <b-col> Status: {{ status }} </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col v-if="option === 'referencia'">
            <b-form-group label="Referencia" label-for="referenciaPago">
              <b-form-input
                type="text"
                required
                v-model="referenciaPago"
                name="referenciaPago"
                autofocus="autofocus"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="option === 'status'">
            <b-form-group
              label="Status de la Solicitud"
              label-for="statusSolicitud"
            >
              <b-form-select
                v-model="statusSolicitud"
                :options="optionStatus"
                required
                class="form-control"
                name="statusSolicitud"
                autofocus="autofocus"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-form>
      <FlashMessage :error="error" />
    </b-modal>
  </b-container>
</template>

<script>
import FlashMessage from "@/components/FlashMessage";
import { getError } from "@/utils/helpers";
import { capitalize } from "@/utils/helpers.js";
import store from "@/store/index";
export default {
  components: {
    FlashMessage,
  },
  props: ["estampilla", "option"],
  data() {
    return {
      referenciaPago: null,
      error: null,
      autofocus: true,
      statusSolicitud: this.estampilla.status,
      optionStatus: [
        { value: "aprobada", text: "Aprobada" },
        { value: "rechazada", text: "Rechazada" },
        { value: "pendiente", text: "Pendiente" },
        { value: "usada", text: "Usada" },
      ],
    };
  },
  computed: {
    estampillaID: function () {
      return `estampilla-${this.estampilla.id}`;
    },
    status: function () {
      return capitalize(this.estampilla.status);
    },
    modalTitle: function () {
      if (this.option === "referencia") return "Cargar Referencia de Pago";
      else return "Cambiar Status de la Solicitud";
    },
  },
  methods: {
    async updateEstampilla() {
      try {
        if (this.referenciaPago || this.statusSolicitud) {
          // const page = 1;
          const payload = {
            id: this.estampilla.id,
            referencia: this.referenciaPago,
            status: this.statusSolicitud,
          };

          if (this.option === "referencia") {
            await store.dispatch("estampilla/setReferenciaPago", payload);
          } else if (this.option === "status") {
            await store.dispatch("estampilla/setStatusSolicitud", payload);
          }

          this.$emit("refreshTable");
          this.$nextTick(() => {
            this.$bvModal.hide(this.estampillaID);
          });
        }
      } catch (err) {
        this.error = getError(err);
      }
    },
  },
};
</script>

<style></style>
