export default function auth({ to, next, store }) {
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };

  if (!store.getters["auth/authUser"]) {
    store.dispatch("auth/getAuthUser").then(() => {
      if (!store.getters["auth/authUser"]) next(loginQuery);
      else {
        if (store.getters["auth/role"] === 2) {
          next({ name: "TasaSalida" });
        } else if (store.getters["auth/role"] === 3) {
          next({ name: "TasaSanitaria" });
        } else {
          next();
        }
      }
    });
  } else {
    if (store.getters["auth/role"] === 2) {
      next({ name: "TasaSalida" });
    } else if (store.getters["auth/role"] === 3) {
      next({ name: "TasaSanitaria" });
    } else {
      next();
    }
  }
}
