<template>
  <b-container>
    <b-modal
      id="anularTasaModal"
      ref="anularTasaModal"
      name="anularTasaModal"
      centered
      title="Anulacion de Tasa"
      hide-footer
    >
      <b-form @submit.prevent="setNota">
        <b-row>
          <b-col>
            <b-form-group label="Motivo de Anulacion" label-for="nota">
              <b-form-input
                id="nota"
                type="text"
                required
                v-model="nota"
                name="nota"
                list="notas"
              ></b-form-input>
              <datalist id="notas">
                <option v-for="(item, index) in notas" :key="index">
                  {{ item }}
                </option>
              </datalist>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col col offset="9" lg="1">
            <b-button type="submit" size="sm" variant="primary">
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      nota: null,
      notas: [
        "Error en el ticket",
        "Error de impresion",
        "Impresión duplicada",
        "Error de Conexión",
        "Impresora sin papel",
      ],
    };
  },
  methods: {
    setNota() {
      this.$emit("notaAnular", this.nota);
      this.nota = null;
      this.$nextTick(() => {
        this.$bvModal.hide("anularTasaModal");
      });
    },
  },
};
</script>

<style></style>
