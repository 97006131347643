import * as API from "@/services/API";

export default {
  getCliente(placa) {
    return API.apiClient.get(`/clientes/getCliente/=${placa}`);
  },
  getClientes(page) {
    return API.apiClient.get(`/clientes/clientes/?page=${page}`);
  },
  paginateClientes(page) {
    const link = `/clientes/clientes/?page=${page}`;
    return API.apiClient.get(link);
  },
  crearCliente(payload) {
    return API.apiClient.post("/clientes/crearCliente", payload);
  },
  eliminarCliente(clienteID) {
    return API.apiClient.get(`/clientes/eliminar/${clienteID}`);
  },
  updateCliente(payload) {
    return API.apiClient.post("/clientes/update", payload);
  },
};
