import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Leyes from "@/views/Leyes.vue";
import Nosotros from "@/views/Nosotros.vue";
import EstampillaInfo from "@/views/EstampillaInfo.vue";
//import VerifyEmail from "@/views/VerifyEmail.vue";
//import EmailConfirm from "@/views/EmailConfirm.vue";

import Users from "@/views/users/Users.vue";
/*import User from "@/views/users/User.vue";*/

import auth from "@/middleware/auth";
import admin from "@/middleware/admin";
import agent from "@/middleware/agent";
import guest from "@/middleware/guest";
import middlewarePipeline from "@/router/middlewarePipeline";

import Estampillas from "@/views/estampillas/Estampillas.vue";
import Estampilla from "@/views/estampillas/Estampilla.vue";
import EstampillasLote from "@/views/estampillas/EstampillasLote.vue";
import Lotes from "@/views/estampillas/Lotes.vue";

// import Verificacion from "@/views/estampillas/Verificacion.vue";
import Forma001 from "@/views/impuestos/Forma001.vue";
import Forma003 from "@/views/impuestos/Forma003.vue";
import Forma005 from "@/views/impuestos/Forma005.vue";
import Formas from "@/views/impuestos/Formas.vue";

import TasaSalida from "@/views/impuestos/TasaSalida.vue";
import TasaSanitaria from "@/views/impuestos/TasaSanitaria.vue";
import Tasas from "@/views/impuestos/Tasas.vue";
import Clientes from "@/views/clientes/Clientes.vue";

import Perfil from "@/views/configuracion/Perfil.vue";
import ImpuestosSanciones from "@/views/configuracion/ImpuestosSanciones.vue";
import Utes from "@/views/configuracion/Utes.vue";
import Publicacion from "@/views/configuracion/Publicaciones.vue";
import News from "@/components/NewsConfig.vue";
import Publications from "@/components/PublicationsConfig.vue";
import ReleaseForm from "@/views/configuracion/ReleaseForm.vue";
import EditRelease from "@/views/configuracion/EditRelease.vue";
import Validar from "@/views/configuracion/Validar.vue";
import showRelease from "@/views/Publicacion.vue";
import generalViews from "@/views/GeneralViews.vue";

import Reportes from "@/views/reportes/Reportes.vue";
import Historico from "@/views/reportes/Historico.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { middleware: [guest] },
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    meta: { middleware: [guest] },
    component: Login,
  },
  /*{
    path: "/verifyEmail",
    name: "VerifyEmail",
    meta: { middleware: [guest] },
    component: VerifyEmail,
  },
  {
    path: "/email/verify-email",
    name: "EmailConfirm",
    meta: { middleware: [guest] },
    component: EmailConfirm,
  },*/
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { middleware: [auth] },
    component: Dashboard,
  },
  {
    path: "/leyes",
    name: "Leyes",
    meta: { middleware: [guest] },
    component: Leyes,
  },
  {
    path: "/nosotros",
    name: "Nosotros",
    meta: { middleware: [guest] },
    component: Nosotros,
  },
  {
    path: "/estampilla-info",
    name: "EstampillaInfo",
    meta: { middleware: [guest] },
    component: EstampillaInfo,
  },
  {
    path: "/register",
    name: "register",
    meta: { middleware: [guest] },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register"),
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    meta: { middleware: [guest] },
    component: () =>
      import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: { middleware: [guest] },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword"
      ),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/estampillas/estampillas",
    name: "Estampillas",
    meta: { middleware: [auth] },
    component: Estampillas,
  },
  {
    path: "/estampillas/estampilla",
    name: "Estampilla",
    meta: { middleware: [auth] },
    component: Estampilla,
  },
  {
    path: "/estampillas/estampillas-lote",
    name: "EstampillasLote",
    meta: { middleware: [auth] },
    component: EstampillasLote,
  },
  {
    path: "/estampillas/lotes",
    name: "Lotes",
    meta: { middleware: [auth] },
    component: Lotes,
  },
  /* {
    path: "/estampillas/vf",
    name: "Verificacion",
    meta: { middleware: [guest] },
    component: Verificacion,
  },*/
  {
    path: "/impuestos/forma-001",
    name: "Forma-001",
    meta: { middleware: [auth] },
    component: Forma001,
  },
  {
    path: "/impuestos/forma-003",
    name: "Forma-003",
    meta: { middleware: [auth] },
    component: Forma003,
  },
  {
    path: "/impuestos/forma-005",
    name: "Forma005",
    meta: { middleware: [auth] },
    component: Forma005,
  },
  {
    path: "/impuestos/formas",
    name: "Formas",
    meta: { middleware: [auth] },
    component: Formas,
  },
  {
    path: "/impuestos/tasaSalida",
    name: "TasaSalida",
    meta: { middleware: [auth, agent] },
    component: TasaSalida,
  },
  {
    path: "/impuestos/tasaDesinfeccion",
    name: "TasaSanitaria",
    meta: { middleware: [auth, agent] },
    component: TasaSanitaria,
  },
  {
    path: "/impuestos/tasas",
    name: "Tasas",
    meta: { middleware: [auth, admin] },
    component: Tasas,
  },
  {
    path: "/configuracion/users",
    name: "Users",
    meta: { middleware: [auth, agent] },
    component: Users,
  },
  {
    path: "/configuracion/validar",
    name: "Validar",
    meta: { middleware: [auth, agent] },
    component: Validar,
  },
  {
    path: "/clientes/clientes",
    name: "Clientes",
    meta: { middleware: [auth, admin] },
    component: Clientes,
  },
  {
    path: "/reportes/reportes",
    name: "Reportes",
    meta: { middleware: [auth, agent] },
    component: Reportes,
  },
  {
    path: "/reportes/historico",
    name: "Historico",
    meta: { middleware: [auth, admin] },
    component: Historico,
  },
  {
    path: "/configuracion/perfil",
    name: "Perfil",
    meta: { middleware: [auth] },
    component: Perfil,
  },
  {
    path: "/configuracion/impuestosSanciones",
    name: "ImpuestosSanciones",
    meta: { middleware: [auth, admin] },
    component: ImpuestosSanciones,
  },
  {
    path: "/configuracion/utes",
    name: "Utes",
    meta: { middleware: [auth, agent] },
    component: Utes,
  },
  {
    path: "/configuracion/publicaciones",
    name: "Publicaciones",
    meta: { middleware: [auth] },
    component: Publicacion,
  },
  {
    path: "/configuracion/publicaciones/noticias",
    name: "News",
    meta: { middleware: [auth] },
    component: News,
  },
  {
    path: "/configuracion/publicaciones/gacetas",
    name: "Gacetas",
    meta: { middleware: [auth] },
    component: Publications,
  },
  {
    path: "/configuracion/crearPublicacion",
    name: "releaseForm",
    meta: { middleware: [auth] },
    component: ReleaseForm,
  },
  {
    path: "/configuracion/editarPublicacion/:id",
    name: "editRelease",
    meta: { middleware: [auth] },
    component: EditRelease,
  },
  {
    path: "/noticias/:id",
    name: "showNoticias",
    component: showRelease,
  },
  {
    path: "/gacetas/:id",
    name: "showGacetas",
    component: showRelease,
  },
  {
    path: "/publicaciones/:type",
    name: "generalViews",
    component: generalViews,
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
