<template>
  <b-container fluid="md" class="chart-container">
    <b-row class="chart-controls text-right">
      <b-col cols="2" offset-md="9" class="pl-3">
        <b-form-select v-model="filterType" :options="options"></b-form-select>
      </b-col>
      <b-col cols="1">
        <b-button variant="outline-primary" size="sm">Filtrar</b-button>
      </b-col>
    </b-row>
    <b-row class="chart-render">
      <b-col>
        <FormasChart
          :chartFilters="filterType"
          :customStyle="formasStyle"
        ></FormasChart>
      </b-col>
      <b-col>
        <EstampillasChart
          :chartFilters="filterType"
          :customStyle="formasStyle"
        ></EstampillasChart>
      </b-col>
    </b-row>
    <b-row class="chart-render">
      <b-col>
        <SolicitudesChart
          :chartFilters="filterType"
          :customStyle="solicitudesStyle"
        ></SolicitudesChart>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FormasChart from "@/components/FormasChart.vue";
import EstampillasChart from "@/components/EstampillasChart.vue";
import SolicitudesChart from "@/components/SolicitudesChart.vue";

export default {
  components: { FormasChart, EstampillasChart, SolicitudesChart },
  data() {
    return {
      options: [
        { value: 1, text: "Hoy" },
        { value: 2, text: "Ayer" },
        { value: 3, text: "Ultimos 7 dias" },
        { value: 4, text: "Ultimos 15 dias" },
        { value: 5, text: "Ultimos 30 dias" },
        { value: 6, text: "Este año" },
        { value: 6, text: "Por rango" },
      ],
      filterType: 3,
      formasStyle: {
        height: "350px",
        width: "100%",
      },
      estampillasStyle: {
        height: "350px",
        width: "100%",
      },
      solicitudesStyle: {
        height: "350px",
        width: "100%",
      },
    };
  },
};
</script>
