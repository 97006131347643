<template>
  <b-container class="marco">
    <Spinner v-if="loading" />
    <b-row>
      <b-col cols="4"><h5>Reportes de Solicitudes</h5></b-col>
    </b-row>
    <br />
    <b-form @submit.prevent="getReport" @reset.prevent="onReset">
      <b-row>
        <b-col>
          <b-form-group label="Tipo de Reporte" label-for="reportType">
            <b-form-select
              id="reportType"
              v-model="reportType"
              :options="optionReportType"
              required
              class="form-control"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Intervalo" label-for="interval">
            <b-form-select
              id="interval"
              v-model="interval"
              :options="optionInterval"
              required
              class="form-control"
              @change="setInterval"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="interval === 5">
          <b-form-group label="Desde" label-for="from">
            <b-form-datepicker
              id="from"
              v-model="from"
              class="form-control"
              locale="es"
              label-help=""
              label-no-date-selected="Elija Fecha"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col v-if="interval === 5">
          <b-form-group label="Hasta" label-for="to">
            <b-form-datepicker
              id="to"
              v-model="to"
              class="form-control"
              locale="es"
              label-help=""
              label-no-date-selected="Elija Fecha"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="report-buttons">
          <b-button type="submit" variant="primary"> Buscar </b-button>
        </b-col>
        <b-col cols="1" class="report-buttons">
          <b-button type="reset" variant="secondary"> Limpiar </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="8">
          <b-form-group label="Status" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="filterStatusSelected"
              :options="optionsStatus"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              :aria-describedby="ariaDescribedby"
              switches
            ></b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Opción Filtrado por Fecha"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="fieldDateFilter"
              :options="optionsFieldDateFilter"
              :aria-describedby="ariaDescribedby"
              button-variant="outline-primary"
              size="md"
              name="radio-btn-outline"
              buttons
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col col lg="1" sm="4">
          <br />
          <b-form-group>
            <b-form-checkbox
              v-model="op"
              name="operations"
              value="export"
              unchecked-value="show"
            >
              Exportar
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <br />
    <b-row>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Buscar"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col col lg="6" v-if="meta">
        <b-pagination
          v-model="meta.current_page"
          :total-rows="meta.total"
          :per-page="meta.per_page"
          align="fill"
          size="sm"
          class="my-0"
          aria-controls="tasaTable"
          @page-click="setClick"
        ></b-pagination>
        <b-row>
          <b-col cols="4">
            <small>Current Page: {{ meta.current_page }}</small>
          </b-col>
          <b-col col offset="5" lg="3" style="text-align: right">
            <small>Total: {{ meta.total }}</small>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-table
          striped
          hover
          sort-icon-left
          :items="reportData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
        </b-table>
      </b-col>
    </b-row>
    <b-row class="sumary">
      <b-col class="foot-sumary-left">
        <strong>Desde:</strong> {{ summary.from }}
      </b-col>
      <b-col class="foot-sumary"
        ><strong>Hasta:</strong> {{ summary.to }}</b-col
      >
      <b-col class="foot-sumary"
        ><strong>Total Aprobado:</strong> {{ summary.total }}
      </b-col>
      <b-col class="foot-sumary"
        ><strong>UCD Total:</strong> {{ summary.totalUTE }}
      </b-col>
      <b-col class="foot-sumary-right"
        ><strong>Monto Total:</strong> {{ summary.totalMonto }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
import { calcularIntervalo } from "@/utils/helpers";
import Spinner from "@/components/Otros/Spinner.vue";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      reportType: null,
      interval: null,
      from: null,
      to: null,
      sortBy: "approved_at",
      sortDesc: true,
      filter: null,
      op: "show",
      filterOn: [],
      fieldDateFilter: "approved_at",
      filterStatusSelected: ["aprobada"],
      optionsFieldDateFilter: [
        { value: "approved_at", text: "Verificación" },
        { value: "created_at", text: "Creación" },
      ],
      optionsStatus: [
        { item: "pendiente", name: "Pendientes" },
        { item: "aprobada", name: "Aprobadas" },
        { item: "usada", name: "Usadas" },
        { item: "rechazada", name: "Rechazadas" },
      ],
      optionReportType: [
        { value: "estampilla", text: "Estampillas" },
        { value: "forma001", text: "Forma001" },
        { value: "forma003", text: "Forma003" },
        { value: "forma005", text: "Forma005" },
        { value: "tasa", text: "Tasa" },
      ],
      optionInterval: [
        { value: 1, text: "Hoy" },
        { value: 2, text: "Ayer" },
        { value: 3, text: "Últimos 7 dias" },
        { value: 4, text: "Últimos 15 dias" },
        { value: 5, text: "Intervalo de fechas" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("report", [
      "reportData",
      "fields",
      "loading",
      "meta",
      "links",
      "summary",
    ]),
  },
  methods: {
    async getReport() {
      const payload = {
        reportType: this.reportType,
        from: this.from,
        to: this.to,
        status: this.filterStatusSelected,
        orderBy: this.sortBy,
        sortDesc: this.sortDesc,
        fieldDateFilter: this.fieldDateFilter,
        op: this.op,
      };
      if (this.reportType !== "tasa") {
        await store.dispatch("report/getReportsData", payload);
      }
    },
    onReset() {
      this.reportType = null;
      this.interval = null;
      this.from = null;
      this.to = null;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setInterval() {
      if (this.interval !== 5) {
        const { from, to } = calcularIntervalo(this.interval);
        this.from = from;
        this.to = to;
      }
    },
  },
};
</script>

<style>
.marco {
  margin-top: 1.5em !important;
}
.status div {
  margin-left: 5px !important;
  margin-right: 15px !important;
  display: inline !important;
}
.status .custom-control-input {
  margin-right: 5px !important;
}
.report-buttons {
  padding-top: 25px !important;
}
</style>
