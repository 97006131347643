import { getError } from "@/utils/helpers";
import OperationService from "@/services/OperationService";

export const namespaced = true;

function setPaginatedOperations(commit, response) {
  commit("SET_OPERATIONS", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export const state = {
  operations: [],
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_OPERATIONS(state, operations) {
    state.operations = operations;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getOperations({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await OperationService.getOperations(payload);
      setPaginatedOperations(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
};

export const getters = {
  operations: (state) => {
    return state.operations;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
