<template>
  <b-container>
    <b-container fluid class="estampilla-from">
      <b-row>
        <b-col cols="4"><h5>Unidad de Cuenta Dinamica</h5></b-col>
      </b-row>
      <b-row>
        <b-col col lg="4">
          <b-button
            block
            variant="primary"
            @click="openModalAgregarUte($bvModal)"
            >Nuevo Registro
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col col lg="6" class="my-1">
          <b-form-group
            label="Buscar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col col lg="6" v-if="utesMeta">
          <b-pagination
            v-model="utesMeta.current_page"
            :total-rows="utesMeta.total"
            :per-page="utesMeta.per_page"
            align="fill"
            size="sm"
            class="my-0"
            aria-controls="usersTable"
            @page-click="setClick"
          ></b-pagination>
          <b-row>
            <b-col col lg="4">
              <small>Página actual: {{ utesMeta.current_page }}</small>
            </b-col>
            <b-col col offset-lg="5" lg="3" style="text-align: right">
              <small>Total: {{ utesMeta.total }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            striped
            hover
            sort-icon-left
            :items="utes"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(opciones)="data">
              <b-link href="#" class="icon-table">
                <b-icon
                  :id="'update' + data.item.id"
                  icon="pencil-fill"
                  scale="1"
                  variant="secondary"
                  @click="openModalEditUte($bvModal, data.item)"
                >
                </b-icon>
                <b-tooltip
                  :target="'update' + data.item.id"
                  title="Modificar"
                ></b-tooltip>
              </b-link>
              <b-link href="#" class="icon-table">
                <b-icon
                  :id="'eliminar' + data.item.id"
                  icon="x-circle-fill"
                  scale="1"
                  variant="danger"
                  @click="eliminarUte(data.item.id)"
                >
                </b-icon>
                <b-tooltip
                  :target="'eliminar' + data.item.id"
                  title="Eliminar"
                ></b-tooltip>
              </b-link>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <AgregarUteModal @nuevoUte="getUtes" />
    <EditUteModal :ute="ute" @editUte="getUtes" />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
import AgregarUteModal from "@/components/Modals/AgregarUteModal";
import EditUteModal from "@/components/Modals/EditUteModal";

export default {
  name: "Utes",
  components: { AgregarUteModal, EditUteModal },
  computed: {
    ...mapGetters("definiciones", ["utes", "utesMeta"]),
  },
  data() {
    return {
      option: null,
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      ute: null,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "periodo", sortable: true, label: "Periodo" },
        { key: "valor", sortable: true, label: "Valor Bs." },
        { key: "fecha", sortable: true, label: "Registrado el" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
    };
  },
  mounted() {
    this.getUtes();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getUtes() {
      await store.dispatch("definiciones/getUtes", this.currentPage);
    },
    async setClick(event, page) {
      event.preventDefault();
      await store.dispatch("definiciones/getUtes", page);
    },
    openModalAgregarUte(modal) {
      modal.show("agregarUteModal");
    },
    openModalEditUte(modal, ute) {
      this.ute = ute;
      modal.show("editUteModal");
    },
    async eliminarUte(uteID) {
      if (
        confirm("El registro sera eliminado del sistema, ¿Está de acuerdo?")
      ) {
        await store.dispatch("definiciones/deleteUte", uteID);
        await this.getUtes();
      }
    },
  },
};
</script>
<style>
.icon-table {
  margin: 5px;
}
</style>
