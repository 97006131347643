<template>
  <b-container>
    <b-container fluid class="estampilla-from">
      <div class="col-12 mb-2 mt-4" v-if="this.$route.name != 'Publicaciones'">
        <!-- llamamos al componente para Crear   -->
        <router-link
          to="/configuracion/crearPublicacion"
          class="btn btn-success"
          ><i class="ti-plus"></i> Crear Publicación
        </router-link>
      </div>
      <div>
        <b-row class="mt-2" v-if="this.$route.name != 'Publicaciones'">
          <b-col>
            <router-link :to="{ name: 'News' }" class="btn btn-link"
              ><b-icon icon="envelope"></b-icon> Noticias
            </router-link>
            <router-link :to="{ name: 'Gacetas' }" class="btn btn-link"
              ><b-icon icon="receipt"></b-icon> Gacetas
            </router-link>
          </b-col>
        </b-row>
        <b-row><h2>Noticias</h2></b-row>
        <b-row>
          <b-col class="my-1">
            <b-form-group
              label="Buscar"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="..."
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''"
                    >Limpiar</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col col lg="6" v-if="meta">
            <b-pagination
              v-model="meta.current_page"
              :total-rows="meta.total"
              :per-page="meta.per_page"
              align="fill"
              size="sm"
              class="my-0"
              aria-controls="releaseTable"
              @page-click="setClick"
            ></b-pagination>
            <b-row>
              <b-col col lg="4">
                <small>Página actual: {{ meta.current_page }}</small>
              </b-col>
              <b-col col offset-lg="5" lg="3" style="text-align: right">
                <small>Total: {{ meta.total }}</small>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              striped
              hover
              sort-icon-left
              :items="release"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              id="releaseTable"
            >
              <template #cell(contenido)="data">
                {{ data.item.contenido.substr(0, 200) + "..." }}
              </template>

              <template #cell(destacado)="data">
                <p v-if="data.item.destacado === 'S'">Publicación destacada</p>
              </template>

              <template #cell(acciones)="data">
                <router-link
                  :to="{ name: 'editRelease', params: { id: data.item.id } }"
                  class="btn btn-info"
                >
                  <b-icon icon="pencil-square"></b-icon>
                </router-link>
                <a
                  type="button"
                  @click="deleteRelease(data.item.id)"
                  class="btn btn-danger"
                  ><b-icon icon="trash-fill"></b-icon
                ></a>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "NewsConfig",
  computed: {
    ...mapGetters("publicacion", [
      "release",
      "meta",
      "links",
      "loading",
      "error",
    ]),
  },
  data() {
    return {
      option: null,
      sortBy: "titulo",
      sortDesc: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      usuario: null,
      fields: [
        { key: "titulo", sortable: true, label: "Titulo" },
        { key: "contenido", sortable: true, label: "Contenido" },
        { key: "destacado", sortable: true, label: "Observacion" },
        { key: "autor", sortable: true, label: "Autor(a)" },
        { key: "acciones", sortable: true, label: "Acciones" },
      ],
      news: null,
      ruta: process.env.VUE_APP_API_URL + "/publicaciones/",
    };
  },
  mounted() {
    this.getRelease();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getRelease() {
      await store.dispatch("publicacion/paginatedRelease", this.currentPage);
      this.news = this.release;
    },
    async setClick(event, page) {
      event.preventDefault();
      await store.dispatch("publicacion/paginatedRelease", page);
      this.news = this.release;
    },
    async deleteRelease(id) {
      try {
        if (
          confirm(
            "La publicación será eliminada del sistema, esta opción no se puede revertir. ¿Desea continuar con la operación?"
          )
        ) {
          this.eliminar(id);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async eliminar(id) {
      await store.dispatch("publicacion/deletePublicacion", id);
      await this.getRelease();
    },
    fecha(fecha) {
      var date = new Date(fecha);
      console.log(date.getTime());
      var nueva_fecha =
        "Publicado el: " +
        date.getDate() +
        "/" +
        (date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();

      return nueva_fecha;
    },
  },
};
</script>

<style lang="scss" scoped></style>
