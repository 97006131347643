<template>
  <b-container>
    <Spinner v-if="loading" />
    <b-form @submit.prevent="solicitarLote">
      <b-container fluid class="estampilla-from">
        <b-row>
          <b-col cols="4"><h5>Solicitud de Estampillas por Lote</h5></b-col>
        </b-row>
        <b-row>
          <b-col col lg="6">
            <b-form-group label="Institución/Empresa" label-for="user">
              <b-form-input
                id="user"
                type="text"
                required
                v-model="user"
                autocomplete="user"
                name="user"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="3">
            <b-form-group label="Número / RIF" label-for="dni">
              <b-form-input
                id="dni"
                type="text"
                v-model="dni"
                autocomplete="dni"
                name="dni"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="3">
            <b-form-group label="Periodo" label-for="periodo">
              <b-form-input
                id="periodo"
                type="text"
                required
                v-model="ut.periodo"
                name="periodo"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Valor UCD Bs." label-for="valor">
              <b-form-input
                id="valor"
                type="text"
                required
                v-model="ut.valor"
                name="valor"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Estampilla Denominación"
              label-for="denominacion"
            >
              <b-form-input
                id="denominacion"
                type="number"
                placeholder="0.30"
                required
                v-model="denominacion"
                name="denominacion"
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cantidad de Estampillas" label-for="cantidad">
              <b-form-input
                id="cantidad"
                type="number"
                required
                v-model="cantidad"
                name="cantidad"
                placeholder="0"
                min="2"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Banco" label-for="banco">
              <b-form-select
                id="banco"
                v-model="cuenta"
                :options="optionCuentas"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Total UCD." label-for="totalUT">
              <b-form-input
                id="totalUT"
                type="number"
                required
                v-model="totalUT"
                name="totalUT"
                step="0.01"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Total a Transferir Bs." label-for="monto">
              <b-form-input
                id="monto"
                type="number"
                required
                v-model="monto"
                name="monto"
                step="0.01"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-container>
</template>

<script>
import Spinner from "@/components/Otros/Spinner.vue";
import { getError, capitalize } from "@/utils/helpers";
import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      cuenta: null,
      denominacion: null,
      monto: 0,
      cantidad: null,
      totalUT: 0,
      error: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("definiciones", ["ut", "cuentas", "tipoDocumento"]),
    ...mapGetters("estampilla", ["loading"]),
    optionCuentas: function () {
      const docs = this.cuentas;
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
    optionTipoDocumento: function () {
      const docs = this.tipoDocumento;
      return docs.map((x) => {
        return {
          value: x.id,
          text: x.nombre,
        };
      });
    },
    dni: function () {
      return `${this.authUser.tipoPersona}-${this.authUser.dni}`;
    },
    user: function () {
      return `${capitalize(this.authUser.firstname)} ${capitalize(
        this.authUser.lastname
      )}`;
    },
  },
  methods: {
    async solicitarLote() {
      try {
        const payload = {
          cuentas_id: this.cuenta,
          denominacion: this.denominacion,
          monto: this.monto,
          cantidad: this.cantidad,
          unidad_tributaria_id: this.ut.id,
        };
        await store.dispatch("estampilla/solicitarLote", payload);
        this.$router.push({ path: "/estampillas/lotes" });
      } catch (error) {
        this.error = getError(error);
      }
    },
    openModal(modal) {
      modal.show("lisUserModal");
    },
    setUserData() {
      console.log("evento");
    },
  },
  watch: {
    denominacion: function (value) {
      const monto = value * this.ut.valor * this.cantidad;
      this.monto = monto.toFixed(2);
      this.totalUT = value * this.cantidad;
    },
    cantidad: function (value) {
      const monto = value * this.ut.valor * this.denominacion;
      this.monto = monto.toFixed(2);
      this.totalUT = value * this.denominacion;
    },
  },
  mounted() {},
};
</script>
