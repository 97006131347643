<template>
  <b-container>
    <b-modal
      id="editUsuarioModal"
      ref="editUsuarioModal"
      name="editUsuarioModal"
      size="xl"
      centered
      hide-footer
      title="Modificar Ususario"
    >
      <b-form @submit.prevent="updateUsuario">
        <b-row>
          <b-col>
            <b-form-group label="Nombre" label-for="firstname">
              <b-form-input
                type="text"
                required
                v-model="firstname"
                name="firstname"
                autocomplete="firstname"
                autofocus="autofocus"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Apellido" label-for="lastname">
              <b-form-input
                type="text"
                v-model="lastname"
                name="lastname"
                autocomplete="lastname"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Email" label-for="email">
              <b-form-input
                type="email"
                v-model="email"
                name="email"
                autocomplete="email"
                :disabled="
                  authUser.role === 6 || authUser.role === 5 ? false : true
                "
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Tipo de Persona" label-for="tipoPersona">
              <b-form-select
                id="tipoPersona"
                v-model="tipoPersona"
                :options="optionTipoPersona"
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedúla" label-for="dni">
              <b-form-input
                type="number"
                v-model="dni"
                name="dni"
                autocomplete="dni"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Genero" label-for="sexo">
              <b-form-select
                id="sexo"
                v-model="sexo"
                :options="optionSexo"
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Role" label-for="role">
              <b-form-select
                v-if="authUser.role === 6 || authUser.role === 5"
                id="role"
                v-model="role"
                :options="optionRoles"
                required
                class="form-control"
              ></b-form-select>
              <b-form-select
                v-else
                id="role"
                v-model="role"
                :options="optionRoles"
                required
                disabled
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Lote" label-for="lote">
              <b-form-select
                v-if="authUser.role === 6 || authUser.role === 5"
                id="lote"
                v-model="lote"
                :options="optionLote"
                required
                class="form-control"
              ></b-form-select>
              <b-form-select
                v-else
                id="lote"
                v-model="lote"
                :options="optionLote"
                required
                disabled
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Clave" label-for="password">
              <b-form-input
                type="password"
                v-model="password"
                name="password"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Confirmación Clave"
              label-for="confirmPassword"
            >
              <b-form-input
                type="password"
                v-model="confirmPassword"
                name="confirmPassword"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/index";
import { mapGetters } from "vuex";

export default {
  props: ["usuario"],
  data() {
    return {
      id: null,
      tipoPersona: "V",
      email: null,
      firstname: null,
      lastname: null,
      dni: null,
      sexo: null,
      role: null,
      lote: false,
      password: null,
      confirmPassword: null,
      optionTipoPersona: [
        { value: "V", text: "Natural Venezolano" },
        { value: "E", text: "Natural Extranjero" },
        { value: "J", text: "Jurídica" },
        { value: "G", text: "Gubernamental" },
        { value: "I", text: "Institución Educativa" },
      ],
      optionRole: [
        { value: 1, text: "Usuario" },
        { value: 2, text: "Tasa de salida " },
        { value: 3, text: "Tasa de desinfeción" },
        { value: 4, text: "Agente Superior" },
        { value: 5, text: "Supervisor" },
        { value: 6, text: "Administrador" },
        { value: 7, text: "Empresa" },
        { value: 8, text: "Instituto" },
      ],
      optionSexo: [
        { value: "femenino", text: "Femenino" },
        { value: "masculino", text: "Masculino" },
        { value: "desconocido", text: "Desconocido" },
      ],
      optionLote: [
        { value: false, text: "Deshabilitado" },
        { value: true, text: "Habilitado" },
      ],
    };
  },
  mounted() {
    this.$root.$on("bv::modal::shown", () => {
      if (this.usuario) {
        this.id = this.usuario.id;
        this.firstname = this.usuario.firstname;
        this.lastname = this.usuario.lastname;
        this.email = this.usuario.email;
        this.dni = this.usuario.dni;
        this.role = this.usuario.role;
        this.sexo = this.usuario.sexo;
        this.password = this.usuario.password;
        this.confirmPassword = this.usuario.password;
        this.lote = this.usuario.lote;
        this.tipoPersona = this.usuario.tipoPersona;
      }
    });
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    optionRoles: function () {
      if (this.authUser !== 6) {
        return this.optionRole.filter((role) => role.value !== 6);
      } else {
        return this.optionRole;
      }
    },
  },
  methods: {
    async updateUsuario() {
      let payload = {};
      if (this.password) {
        if (this.password === this.confirmPassword) {
          payload = {
            id: this.id,
            firstname: this.firstname,
            lastname: this.lastname,
            dni: this.dni,
            tipoPersona: this.tipoPersona,
            sexo: this.sexo,
            role: this.role,
            email: this.email,
            password: this.password,
            lote: this.lote,
          };
        } else {
          this.$toast.error("La clave no coincide", { timeout: 3000 });
        }
      } else {
        payload = {
          id: this.id,
          firstname: this.firstname,
          lastname: this.lastname,
          dni: this.dni,
          tipoPersona: this.tipoPersona,
          sexo: this.sexo,
          role: this.role,
          email: this.email,
          lote: this.lote,
        };
      }
      if (payload) {
        await store.dispatch("user/updateUser", payload);
        this.$emit("editUsuario", payload);
        this.$nextTick(() => {
          this.$bvModal.hide("editUsuarioModal");
        });
      }
    },
    resetForm() {
      this.id = null;
      this.firstname = null;
      this.lastname = null;
      this.email = null;
      this.dni = null;
      this.role = null;
      this.sexo = null;
      this.password = null;
      this.confirmPassword = null;
      this.tipoPersona = null;
      this.lote = null;
    },
  },
};
</script>

<style></style>
