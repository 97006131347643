<template>
  <b-container>
    <b-container fluid class="releasePanel">
      <div class="col-12 mb-2 mt-4">
        <!-- llamamos al componente para Crear   -->
        <router-link
          to="/configuracion/crearPublicacion"
          class="btn btn-success"
          ><i class="ti-plus"></i> Crear Publicación
        </router-link>
      </div>
      <div>
        <b-row class="mt-2">
          <b-col>
            <router-link :to="{ name: 'News' }" class="btn btn-link"
              ><i class="ti-email"></i> Noticias
            </router-link>
            <router-link :to="{ name: 'Gacetas' }" class="btn btn-link"
              ><i class="ti-layers-alt"></i> Gacetas
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <News />
        </b-row>
      </div>
    </b-container>
  </b-container>
</template>

<script>
import News from "@/components/NewsConfig.vue";

export default {
  name: "Publicaciones",
  components: { News },
};
</script>
