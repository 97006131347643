import { Bar, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;

export default {
  name: "BarChart",
  extends: Bar,
  mixins: [reactiveProp],
  data() {
    return {
      options: { responsive: true, maintainAspectRatio: false },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
