<template>
  <b-container>
    <b-container fluid class="estampilla-from">
      <b-form @submit.prevent="buscarCliente" @reset.prevent="resetForm">
        <b-row>
          <b-col col="6" lg="6"><h5>Tasa de Salida</h5></b-col>
          <b-col col offset="2" lg="4">
            <b-button variant="success" block @click="reportOp">
              Reporte de Operaciones
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col col lg="4" sm="4">
            <b-form-group label="UCD" label-for="cantidadUT">
              <b-form-input
                id="cantidadUT"
                type="text"
                v-model="cantidadUT"
                disabled
                name="cantidadUT"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="4" sm="4">
            <b-form-group label="Monto Bs." label-for="monto">
              <b-form-input
                id="monto"
                type="text"
                v-model="monto"
                name="monto"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="4" sm="4">
            <b-form-group label="Fecha" label-for="fecha">
              <b-form-input
                id="fecha"
                type="text"
                v-model="fecha"
                name="fecha"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <br />
      <b-row>
        <b-col col offset-lg="3" lg="6">
          <b-button
            type="button"
            block
            variant="primary"
            @click="imprimirRecibo()"
          >
            IMPRIMIR
          </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="4"><h5>Tasas Recientes</h5></b-col>
      </b-row>
      <b-row>
        <b-col col lg="6" class="my-1">
          <b-form-group
            label="Buscar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col col lg="6" v-if="meta">
          <b-pagination
            v-model="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            align="fill"
            size="sm"
            class="my-0"
            aria-controls="tasaTable"
            @page-click="setClick"
          ></b-pagination>
          <b-row>
            <b-col col lg="4">
              <small>Current Page: {{ meta.current_page || 0 }}</small>
            </b-col>
            <b-col col offset-lg="5" lg="3" style="text-align: right">
              <small>Total: {{ meta.total || 0 }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            id="tasaTable"
            ref="tasaTable"
            striped
            hover
            sort-icon-left
            :items="tasas"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            small
          >
            <template #cell(opciones)="data">
              <b-icon
                :id="'anular' + data.item.id"
                icon="x-square-fill"
                scale="2"
                variant="warning"
                @click="openModalAnular($bvModal, data.item.id)"
              >
              </b-icon>
              <b-tooltip
                :target="'anular' + data.item.id"
                title="Anular"
              ></b-tooltip>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <AnularTasaModal @notaAnular="notaAnular" />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import AnularTasaModal from "@/components/Modals/AnularTasaModal.vue";
import store from "@/store/index";

export default {
  name: "TasaSalida",
  components: {
    AnularTasaModal,
  },
  data() {
    return {
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      tasaID: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      cantidadUT: null,
      concepto: null,
      fields: [
        { key: "id", sortable: true, label: "Nro" },
        { key: "codigo", sortable: true, label: "Codigo" },
        { key: "fecha", sortable: true, label: "Fecha" },
        { key: "cantidadUT", sortable: true, label: "UCD" },
        { key: "monto", sortable: true, label: "Monto Bs." },
        { key: "status", sortable: true, label: "Status" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
    };
  },
  mounted() {
    this.getImpuestoSancionPorModulo("tasa_salida");
    this.getTasas();
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("tasa", ["tasas", "meta"]),
    ...mapGetters("definiciones", ["ut", "impuestosSanciones"]),
    fecha: function () {
      return new Date().toLocaleDateString("es-VE");
    },
    monto: function () {
      return this.cantidadUT * this.ut.valor;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openModalListado(modal) {
      modal.show("listUserService");
    },
    openModalAddClient(modal) {
      modal.show("agregarCienteModal");
    },
    openModalAnular(modal, id) {
      this.tasaID = id;
      modal.show("anularTasaModal");
    },
    async imprimirRecibo() {
      const payload = {
        id: null,
        cantidadUT: this.cantidadUT,
        tipo: "salida",
        valorUT: this.ut.valor,
        concepto: this.concepto,
      };
      await store.dispatch("tasa/crearTasa", payload);
      await this.getTasas();
    },
    async getTasas() {
      await store.dispatch("tasa/getTasas", {
        tipo: "salida",
        page: this.currentPage,
      });
    },
    async getImpuestoSancionPorModulo(modulo) {
      await store.dispatch("definiciones/getImpuestoSancionPorModulo", modulo);
      const { cantidad_ut, concepto } = this.impuestosSanciones[0];
      this.cantidadUT = cantidad_ut;
      this.concepto = concepto;
    },
    async notaAnular(nota) {
      const payload = {
        tasaID: this.tasaID,
        nota,
      };
      await store.dispatch("tasa/anularTasa", payload);
      await this.getTasas();
    },
    async setClick(event, page) {
      event.preventDefault();
      await store.dispatch("tasa/paginateTasas", { page, tipo: "salida" });
    },
    resetForm() {
      this.cliente = null;
      this.empresa = null;
      this.placa = null;
    },
    async reportOp() {
      if (
        confirm(
          "Usteded Imprimira un resumen de operaciones. ¿Esta de acuerdo?"
        )
      ) {
        await store.dispatch("tasa/reportOp", "salida");
      }
    },
  },
};
</script>
