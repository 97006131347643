import { getError } from "@/utils/helpers";
import UserService from "@/services/UserService";

export const namespaced = true;

function setPaginatedUsers(commit, response) {
  commit("SET_USERS", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

export const state = {
  users: [],
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getUsers({ commit }, { page, filters }) {
    try {
      commit("SET_LOADING", true);
      const response = await UserService.getUsers(page, filters);
      setPaginatedUsers(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async paginateUsers({ commit }, page) {
    try {
      commit("SET_LOADING", true);
      const response = await UserService.paginateUsers(page);
      setPaginatedUsers(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async crearUser({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await UserService.crearUser(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 8000 });
    }
  },
  async updateUser({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await UserService.updateUser(payload);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async deleteUser({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      const response = await UserService.deleteUser(id);
      const { message } = response.data;
      this._vm.$toast.success(message, { timeout: 3000 });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
};

export const getters = {
  users: (state) => {
    return state.users;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
