<template>
  <b-container>
    <b-container fluid class="estampilla-from">
      <b-form @submit.prevent="buscarCliente" @reset.prevent="resetForm">
        <b-row>
          <b-col col lg="6"><h5>Tasa por Servicio de Desinfección</h5></b-col>
          <b-col col offset="2" lg="4">
            <b-button variant="success" block @click="reportOp">
              Reporte de Operaciones
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col col lg="3" sm="2">
            <b-form-group label="Placa" label-for="placa">
              <b-form-input
                id="placa"
                type="text"
                v-model="placa"
                autocomplete="placa"
                name="placa"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="4" sm="4">
            <b-form-group label="Empresa" label-for="empresa">
              <b-form-input
                id="empresa"
                type="text"
                v-model="empresa"
                autocomplete="empresa"
                name="empresa"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col col lg="5">
            <b-button type="reset" variant="secondary" size="md">
              Limpiar
            </b-button>
            &nbsp;
            <b-button
              type="button"
              variant="primary"
              @click="openModalAddClient($bvModal)"
              size="md"
            >
              Registrar
            </b-button>
            &nbsp;
            <b-button
              type="button"
              variant="info"
              @click="openModalListado($bvModal)"
              size="md"
            >
              Listado
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <br />
      <b-row>
        <b-col col offset-lg="3" lg="6">
          <b-button
            v-if="cliente"
            type="button"
            block
            variant="primary"
            @click="imprimirRecibo()"
          >
            IMPRIMIR
          </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col cols="4"><h5>Tasas Recientes</h5></b-col>
      </b-row>
      <b-row>
        <b-col col lg="6" class="my-1">
          <b-form-group
            label="Buscar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="..."
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col col lg="6">
          <b-pagination
            v-model="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            align="fill"
            size="sm"
            class="my-0"
            aria-controls="tasaTable"
            @page-click="setClick"
          ></b-pagination>
          <b-row>
            <b-col col lg="4">
              <small>Current Page: {{ meta.current_page }}</small>
            </b-col>
            <b-col col offset-lg="5" lg="3" style="text-align: right">
              <small>Total: {{ meta.total }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            id="tasaTable"
            ref="tasaTable"
            striped
            hover
            sort-icon-left
            :items="tasas"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            small
          >
            <template #cell(opciones)="data">
              <b-icon
                :id="'anular' + data.item.id"
                icon="x-square-fill"
                scale="2"
                variant="warning"
                @click="openModalAnular($bvModal, data.item.id)"
              >
              </b-icon>
              <b-tooltip
                :target="'anular' + data.item.id"
                title="Anular"
              ></b-tooltip>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <AnularTasaModal @notaAnular="notaAnular" />
    <ListClientesModal @nuevoCliente="clientSelected" />
    <AgregarClienteModal @nuevoCliente="clientSelected" />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import ListClientesModal from "@/components/Modals/ListClientesModal.vue";
import AgregarClienteModal from "@/components/Modals/AgregarClienteModal.vue";
import AnularTasaModal from "@/components/Modals/AnularTasaModal.vue";
import store from "@/store/index";

export default {
  name: "TasaSanitaria",
  components: {
    ListClientesModal,
    AgregarClienteModal,
    AnularTasaModal,
  },
  data() {
    return {
      cliente: null,
      placa: null,
      empresa: null,
      cantidadUT: null,
      concepto: null,
      sortBy: "fecha",
      sortDesc: true,
      filter: null,
      filterOn: [],
      tasaID: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      fields: [
        { key: "id", sortable: true, label: "Nro" },
        { key: "codigo", sortable: true, label: "Codigo" },
        { key: "empresa", sortable: true, label: "Empresa" },
        { key: "placa", sortable: true, label: "Placa" },
        { key: "fecha", sortable: true, label: "Fecha" },
        { key: "cantidadUT", sortable: true, label: "UT" },
        { key: "status", sortable: true, label: "Status" },
        { key: "opciones", sortable: false, label: "Opciones" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("tasa", ["tasas", "meta"]),
    ...mapGetters("definiciones", ["ut", "impuestosSanciones"]),
  },
  mounted() {
    this.getImpuestoSancionPorModulo("tasa_desinfeccion");
    this.getTasas();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clientSelected(client) {
      this.cliente = client[0];
      this.placa = client[0].placa;
      this.empresa = client[0].empresa;
    },
    openModalListado(modal) {
      modal.show("listUserService");
    },
    openModalAddClient(modal) {
      modal.show("agregarCienteModal");
    },
    openModalAnular(modal, id) {
      this.tasaID = id;
      modal.show("anularTasaModal");
    },
    async imprimirRecibo() {
      if (this.cliente) {
        this.cliente.cantidadUT = this.cantidadUT;
        this.cliente.valorUT = this.ut.valor;
        this.cliente.tipo = "sanitaria";
        this.cliente.concepto = this.concepto;
        await store.dispatch("tasa/crearTasa", this.cliente);
        this.cliente = null;
        this.empresa = null;
        this.placa = null;
        await this.getTasas();
      } else {
        this.$toast.warn("Ningun cliente seleccionado", { timeout: 3000 });
      }
    },
    async getTasas() {
      await store.dispatch("tasa/getTasas", {
        tipo: "sanitaria",
        page: this.currentPage,
      });
    },
    async notaAnular(nota) {
      const payload = {
        tasaID: this.tasaID,
        nota,
      };
      await store.dispatch("tasa/anularTasa", payload);
      await this.getTasas();
    },
    async setClick(event, page) {
      event.preventDefault();
      await store.dispatch("tasa/paginateTasas", { page, tipo: "sanitaria" });
    },
    resetForm() {
      this.cliente = null;
      this.empresa = null;
      this.placa = null;
    },
    async getImpuestoSancionPorModulo(modulo) {
      await store.dispatch("definiciones/getImpuestoSancionPorModulo", modulo);
      const { cantidad_ut, concepto } = this.impuestosSanciones[0];
      this.cantidadUT = cantidad_ut;
      this.concepto = concepto;
    },
    async reportOp() {
      if (
        confirm(
          "Usteded Imprimira un resumen de operaciones. ¿Esta de acuerdo?"
        )
      ) {
        await store.dispatch("tasa/reportOp", "sanitaria");
      }
    },
  },
};
</script>
