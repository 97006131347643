<template>
  <b-container>
    <b-form @submit.prevent="getFilters" @reset.prevent="onReset">
      <b-row>
        <b-col>
          <b-form-group label="Tipo de filtro" label-for="filterType">
            <b-form-select
              id="filterType"
              v-model="filterType"
              :options="optionFilterType"
              class="form-control"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="filterType !== 'created_at' && filterType !== 'approved_at'"
        >
          <b-form-group label="Texto" label-for="search-input">
            <b-form-input
              id="search-input"
              v-model="search"
              type="search"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col
          v-if="filterType === 'created_at' || filterType === 'approved_at'"
        >
          <b-form-group label="Intervalo" label-for="interval">
            <b-form-select
              id="interval"
              v-model="interval"
              :options="optionInterval"
              class="form-control"
              @change="setInterval"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="interval === 5">
          <b-form-group label="Desde" label-for="from">
            <b-form-datepicker
              id="from"
              v-model="from"
              class="form-control"
              locale="es"
              label-help=""
              label-no-date-selected="Elija Fecha"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col v-if="interval === 5">
          <b-form-group label="Hasta" label-for="to">
            <b-form-datepicker
              id="to"
              v-model="to"
              class="form-control"
              locale="es"
              label-help=""
              label-no-date-selected="Elija Fecha"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="report-buttons">
          <b-button type="submit" variant="primary"> Buscar </b-button>
        </b-col>
        <b-col cols="1" class="report-buttons">
          <b-button type="reset" variant="secondary"> Limpiar </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-form-group label="Status" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="filterStatusSelected"
              :options="optionsStatus"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              :aria-describedby="ariaDescribedby"
              switches
            ></b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { calcularIntervalo } from "@/utils/helpers";
export default {
  data() {
    return {
      filterType: null,
      interval: null,
      from: null,
      to: null,
      search: null,
      filterStatusSelected: ["pendiente"],
      optionFilterType: [
        { value: "codigo", text: "Codigo" },
        { value: "firstname", text: "Usuario" },
        { value: "dni", text: "Ced/Rif" },
        { value: "referencia", text: "Referencia" },
        { value: "created_at", text: "Fecha Creacion" },
        { value: "approved_at", text: "Fecha Aprovacion" },
      ],
      optionInterval: [
        { value: 1, text: "Hoy" },
        { value: 2, text: "Ayer" },
        { value: 3, text: "Últimos 7 dias" },
        { value: 4, text: "Últimos 15 dias" },
        { value: 5, text: "Intervalo de fechas" },
      ],
      optionsStatus: [
        { item: "pendiente", name: "Pendientes" },
        { item: "aprobada", name: "Aprobadas" },
        { item: "usada", name: "Usadas" },
        { item: "rechazada", name: "Rechazadas" },
      ],
    };
  },
  methods: {
    getFilters() {
      const customFilters = {
        filterType: this.filterType,
        from: this.from,
        to: this.to,
        status: this.filterStatusSelected,
        search: this.search,
      };
      this.$emit("refreshTableFiltros", customFilters);
    },
    setInterval() {
      if (this.interval !== 5) {
        const { from, to } = calcularIntervalo(this.interval);
        this.from = from;
        this.to = to;
      }
    },
    onReset() {
      this.from = null;
      this.to = null;
      this.status = [];
      this.search = null;
      this.filterType = null;
      this.interval = null;
    },
  },
};
</script>
