import * as API from "@/services/API";

export default {
  getPublicaciones() {
    return API.apiClient.get(`/publicaciones/release`);
  },

  releasePaginated(page) {
    return API.apiClient.get(`/publicaciones/publicaciones?page=${page}`);
  },

  newsPaginated(page) {
    return API.apiClient.get(`/publicaciones/gacetas?page=${page}`);
  },

  destacadasPaginated(page) {
    return API.apiClient.get(`/publicaciones/destacadas?page=${page}`);
  },

  getRelease() {
    return API.apiClient.get(`/home/publications`);
  },

  getGacetas() {
    return API.apiClient.get(`/home/news`);
  },

  getRel(relId) {
    return API.apiClient.get(`/publicaciones/getPublicacion/${relId}`);
  },

  createRelease(payload) {
    return API.apiClient.post("/publicaciones/crearPublicacion", payload);
  },

  editRelease(payload) {
    return API.apiClient.post("/publicaciones/editarPublicacion", payload);
  },

  deleteRelease(relId) {
    return API.apiClient.post(`/publicaciones/deletePublicacion/${relId}`);
  },

  getRandom() {
    return API.apiClient.get(`/publicaciones/randRelease`);
  },
};
