<template>
  <div>
    <Menu />
    <div class="dashboard-separador"></div>
    <b-container>
      <b-row class="text-center">
        <b-col col lg="12" class="my-3">
          <b-img
            width="1000"
            height="1000"
            :src="require('@/assets/img/info/estampilla_info.jpg')"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
    <br />
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "EstampillaInfo",
  components: {
    Menu,
    Footer,
  },
};
</script>

<style></style>
