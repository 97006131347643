import { getError } from "@/utils/helpers";
import ReportService from "@/services/ReportService";
import { renderReportData, calcularIntervalo } from "@/utils/helpers";

export const namespaced = true;

function setPaginatedReport(commit, response) {
  const { reportData, fields, summary } = response.data;
  commit("SET_FIELDS", fields);
  commit("SET_REPORT_DATA", reportData.data);
  commit("SET_REPORT_SUMMARY", summary);
  commit("SET_META", reportData.meta);
  commit("SET_LINKS", reportData.links);
  commit("SET_LOADING", false);
}
/*
function setPaginatedChart(commit, response) {
  const { estampillasData, formasData, estampillaSumary, formasSumary } =
    response.data;
  commit("SET_ESTAMPILLA_DATA", estampillasData);
  commit("SET_FORMA_DATA", formasData);
  commit("SET_ESTAMPILLA_SUMARY", estampillaSumary);
  commit("SET_FORMA_SUMARY", formasSumary);
}
*/
export const state = {
  reportData: [],
  fields: [],
  summary: {
    from: null,
    to: null,
    total: null,
    totalUTE: null,
    totalMonto: null,
  },
  estampillasData: null,
  formasData: null,
  estampillaSumary: null,
  formasSumary: null,
  reportType: null,
  meta: null,
  links: null,
  loading: false,
  error: null,
  interval: calcularIntervalo(3),
};

export const mutations = {
  SET_ESTAMPILLA_DATA(state, estampillasData) {
    state.estampillasData = estampillasData;
  },
  SET_FORMA_DATA(state, formasData) {
    state.formasData = formasData;
  },
  SET_ESTAMPILLA_SUMARY(state, estampillaSumary) {
    state.estampillaSumary = estampillaSumary;
  },
  SET_FORMA_SUMARY(state, formasSumary) {
    state.formasSumary = formasSumary;
  },
  SET_REPORT_DATA(state, reportData) {
    state.reportData = renderReportData(reportData, state.reportType);
  },
  SET_FIELDS(state, fields) {
    state.fields = fields;
  },
  SET_REPORT_SUMMARY(state, summary) {
    state.summary = summary;
  },
  SET_REPORT_TYPE(state, reportType) {
    state.reportType = reportType;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async getReportsData({ commit }, payload) {
    const { reportType, op } = payload;
    commit("SET_LOADING", true);
    commit("SET_REPORT_TYPE", reportType);
    try {
      if (op === "show") {
        const response = await ReportService.getReportsData(payload);
        setPaginatedReport(commit, response);
      } else {
        const response = await ReportService.getReportsDataExcel(payload);
        var newBlob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.target = "_blank";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
        commit("SET_LOADING", false);
      }
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async getChartData({ commit /*state*/ }, payload = null) {
    commit("SET_LOADING", true);
    try {
      //let interval;
      if (!payload) {
        //interval = state.interval;
      }
      //interval = payload;
      //const response = await ReportService.getChartData(interval);
      //setPaginatedChart(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async reportEstampillas({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ReportService.reportEstampillas(payload);
      setPaginatedReport(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async reportHistorico({ commit }, payload) {
    commit("SET_LOADING", true);
    try {
      const response = await ReportService.reportHistorico(payload);
      setPaginatedReport(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async paginateReport({ commit }, page) {
    commit("SET_LOADING", true);
    try {
      const response = await ReportService.paginateReport(page);
      setPaginatedReport(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
};

export const getters = {
  fields: (state) => {
    return state.fields;
  },
  reportData: (state) => {
    return state.reportData;
  },
  summary: (state) => {
    return state.summary;
  },
  estampillasData: (state) => {
    return state.estampillasData;
  },
  formasData: (state) => {
    return state.formasData;
  },
  estampillaSumary: (state) => {
    return state.estampillaSumary;
  },
  formasSumary: (state) => {
    return state.formasSumary;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
