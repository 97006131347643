import * as API from "@/services/API";

export default {
  getReportsData(payload) {
    return API.apiClient.post("/report/getReportsData", payload);
  },
  getChartData(payload) {
    return API.apiClient.post("/report/getChartData", payload);
  },
  reportEstampillas(payload) {
    return API.apiClient.post("/report/reportEstampillas", payload);
  },
  reportTasa(payload) {
    return API.apiClient.post("/report/reportTasas", payload);
  },
  reportHistorico(payload) {
    return API.apiClient.post("/report/reportHistorico", payload);
  },
  paginateReport(page) {
    const link = `/report/getReportsData/?page=${page}`;
    return API.apiClient.get(link);
  },
  paginateHistorico(page) {
    const link = `/report/reportHistorico/?page=${page}`;
    return API.apiClient.get(link);
  },
  getReportsDataExcel(payload) {
    return API.apiClient.post("/report/getReportsData", payload, {
      responseType: "arraybuffer",
    });
  },
};
