import { getError } from "@/utils/helpers";
import PublicacionesService from "@/services/PublicacionesService";

export const namespaced = true;

function setPaginatedPublicaciones(commit, response) {
  commit("SET_RELEASE", response.data.data);
  commit("SET_RAND_RELEASE", response.data.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

function setPublicaciones(commit, response) {
  commit("SET_RELEASE", response.data);
  commit("SET_RAND_RELEASE", response.data);
  commit("SET_META", response.data.meta);
  commit("SET_LINKS", response.data.links);
  commit("SET_LOADING", false);
}

function setRandom(commit, response) {
  commit("SET_RAND_RELEASE", response.data);
}

export const state = {
  release: [],
  rand_release: [],
  meta: null,
  links: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_RELEASE(state, release) {
    state.release = release;
  },
  SET_RAND_RELEASE(state, rand_release) {
    state.rand_release = rand_release;
  },
  SET_META(state, meta) {
    state.meta = meta;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};

export const actions = {
  async paginatedRelease({ commit }, page) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.releasePaginated(page);
      setPaginatedPublicaciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },

  async paginatedPublications({ commit }, page) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.newsPaginated(page);
      setPaginatedPublicaciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },

  async paginatedDestacadas({ commit }, page) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.destacadasPaginated(page);
      setPaginatedPublicaciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },

  async getRelease({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.getRelease();
      setPublicaciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },

  async getPublications({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.getGacetas();
      setPublicaciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
  async getRandom({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.getRandom();
      setRandom(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },

  async getGaceta({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.getGacetas();
      setPaginatedPublicaciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },

  async getPublicacion({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await PublicacionesService.getRel(id);
      setPublicaciones(commit, response);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },

  async deletePublicacion({ commit }, id) {
    commit("SET_LOADING", true);
    try {
      await PublicacionesService.deleteRelease(id).then(() => {
        this._vm.$toast.success("Publicación eliminada con éxito", {
          timeout: 3000,
        });
      });
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR", getError(error));
      this._vm.$toast.error(getError(error), { timeout: 3000 });
    }
  },
};

export const getters = {
  release: (state) => {
    return state.release;
  },
  rand_release: (state) => {
    return state.rand_release;
  },
  meta: (state) => {
    return state.meta;
  },
  links: (state) => {
    return state.links;
  },
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};
