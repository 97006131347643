<template>
  <b-container>
    <b-row>
      <b-col col lg="9">
        <b-navbar toggleable="lg" type="light" variant="faded">
          <b-container>
            <b-navbar-brand href="/dashboard">
              <b-img :src="require('@/assets/img/logos/logo-01.png')"></b-img>
            </b-navbar-brand>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" class="d-flex">
              <b-navbar-nav>
                <b-nav-item-dropdown text="Estampillas">
                  <b-dropdown-item href="#">
                    <router-link to="/estampillas/estampilla">
                      Nueva Estampilla
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    <router-link to="/estampillas/estampillas">
                      Solicitudes de Estampillas
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="is_loteRole" href="#">
                    <router-link to="/estampillas/estampillas-lote">
                      Nueva Solicitud por Lote
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="is_loteRole" href="#">
                    <router-link to="/estampillas/lotes">
                      Solicitudes por Lote
                    </router-link>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Impuestos">
                  <b-dropdown-item href="#">
                    <router-link to="/impuestos/forma-001">
                      Forma 001
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    <router-link to="/impuestos/forma-003">
                      Forma 003
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    <router-link to="/impuestos/forma-005">
                      Forma 005
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item href="#">
                    <router-link to="/impuestos/formas">
                      Formas Recientes
                    </router-link>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown v-if="is_heightRole" text="Reportes">
                  <b-nav-item href="#">
                    <router-link to="/reportes/reportes">Reportes</router-link>
                  </b-nav-item>
                  <b-nav-item v-if="authUser.role === 6" href="#">
                    <router-link to="/reportes/historico"
                      >Historico</router-link
                    >
                  </b-nav-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Configuracion">
                  <b-nav-item href="#" v-if="is_heightRole">
                    <router-link to="/configuracion/users"
                      >Usuarios
                    </router-link>
                  </b-nav-item>
                  <b-nav-item href="#">
                    <router-link to="/configuracion/perfil">Perfil</router-link>
                  </b-nav-item>
                  <b-nav-item href="#" v-if="is_heightRole">
                    <router-link to="/configuracion/utes">UCD</router-link>
                  </b-nav-item>
                  <b-nav-item href="#" v-if="is_heightRole">
                    <router-link to="/configuracion/impuestosSanciones">
                      Impuestos Sanciones
                    </router-link>
                  </b-nav-item>
                  <!--Publicaciones-->
                  <b-nav-item
                    href="#"
                    v-if="
                      authUser.role === 4 ||
                      authUser.role === 5 ||
                      authUser.role === 6
                    "
                  >
                    <router-link to="/configuracion/publicaciones">
                      Publicaciones
                    </router-link>
                  </b-nav-item>
                  <b-nav-item href="#" v-if="is_heightRole">
                    <router-link to="#"> Cuentas Bancarias </router-link>
                  </b-nav-item>
                  <b-nav-item href="#" v-if="is_heightRole">
                    <router-link to="#"> Tipos de Documentos </router-link>
                  </b-nav-item>
                  <b-nav-item href="#" v-if="is_heightRole">
                    <router-link to="#"> Definiciones del Sistema </router-link>
                  </b-nav-item>
                </b-nav-item-dropdown>
                <b-nav-item href="#" v-if="is_allowed">
                  <router-link to="/configuracion/validar">
                    Verificar
                  </router-link>
                </b-nav-item>
                <b-nav-item href="#" @click="logout">Salir</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-container>
        </b-navbar>
      </b-col>
      <b-col>
        <b-navbar-brand toggleable="lg" type="light" variant="faded" id="logo2">
          <b-img
            :src="require('@/assets/img/logos/am-02.png')"
            width="175"
          ></b-img>
        </b-navbar-brand>
      </b-col>
    </b-row>
    <div class="dashboard-separador"></div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "MenuAuth",
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  mounted() {
    store.dispatch("definiciones/getBancos");
    store.dispatch("definiciones/getUT");
    store.dispatch("definiciones/getTipoDocumento");
    store.dispatch("definiciones/getCuentas");
  },
  computed: {
    ...mapGetters("auth", [
      "authUser",
      "is_allowed",
      "is_heightRole",
      "is_loteRole",
    ]),
    horaActual() {
      return new Date().toLocaleDateString("es-VE");
    },
  },
};
</script>
<style>
.dashboard-user {
  position: relative;
  top: -20px;
}
.dashboard-user div {
  position: absolute;
}
.user-icon {
  left: 0%;
}
.user-name {
  vertical-align: middle;
  text-align: right;
}
.user-name {
  left: 45px;
  width: 100px;
}
</style>
