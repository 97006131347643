<template>
  <b-container>
    <Spinner v-if="loading" />
    <qrcode-stream v-if="!decodedString" @decode="onDecode"></qrcode-stream>
    <b-form
      v-if="decodedString"
      @submit.prevent="usar"
      @reset.prevent="escanear"
    >
      <b-container fluid>
        <br />
        <b-row>
          <b-col>
            <b-form-group label="Usuario" label-for="user">
              <b-form-input
                id="user"
                type="text"
                required
                v-model="user"
                name="user"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedula / RIF" label-for="dni">
              <b-form-input
                id="dni"
                type="text"
                v-model="estampilla.dni"
                name="dni"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Status" label-for="status">
              <b-form-select
                v-model="estampilla.status"
                :options="optionStatus"
                required
                class="form-control"
                name="status"
                autofocus="autofocus"
                disabled
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Codigo" label-for="codigo">
              <b-form-input
                id="codigo"
                type="text"
                required
                v-model="estampilla.codigo"
                name="codigo"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Denominacion" label-for="denominacion">
              <b-form-input
                id="denominacion"
                type="text"
                v-model="estampilla.denominacion"
                name="denominacion"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Fecha de la Solicitud" label-for="fecha">
              <b-form-input
                v-model="estampilla.fecha"
                id="fecha"
                type="text"
                class="form-control"
                name="fecha"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            ><b-form-group label="Tramite" label-for="tramite">
              <b-form-input
                v-model="estampilla.tramite"
                id="tramite"
                type="text"
                class="form-control"
                name="tramite"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-button
              v-if="estampilla.status == 'aprobada'"
              type="submit"
              block
              variant="primary"
            >
              Usar Estampilla
            </b-button>
            <b-button v-else type="reset" block variant="secondary">
              Escanear QR
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </b-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import Spinner from "@/components/Otros/Spinner.vue";
import store from "@/store/index";
import { capitalize } from "@/utils/helpers";
import { mapGetters } from "vuex";
export default {
  components: {
    QrcodeStream,
    Spinner,
  },
  data() {
    return {
      decodedString: null,
      loading: false,
      user: null,
      dni: null,
      optionStatus: [
        { value: "aprobada", text: "Aprobada" },
        { value: "rechazada", text: "Rechazada" },
        { value: "pendiente", text: "Pendiente" },
        { value: "usada", text: "Usada" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("estampilla", ["estampilla"]),
  },
  methods: {
    async onDecode(token) {
      try {
        this.loading = true;
        if (token.includes("token")) {
          this.decodedString = token.split("=")[1];
        } else {
          this.decodedString = token;
        }
        await store.dispatch("estampilla/validar", {
          token: this.decodedString,
        });
        this.user = `${capitalize(this.estampilla.firstname)} ${capitalize(
          this.estampilla.lastname
        )}`;
        this.dni = `${this.estampilla.tipoPersona}-${this.estampilla.dni}`;
        this.statusSolicitud = this.estampilla.status;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async usar() {
      try {
        const payload = {
          id: this.estampilla.id,
          received_in: this.authUser.id,
        };
        this.loading = true;
        await store.dispatch("estampilla/usarEstampilla", payload);
        this.loading = false;
        this.decodedString = false;
      } catch (error) {
        console.log(error);
      }
    },
    escanear() {
      this.decodedString = null;
      this.user = null;
      this.dni = null;
    },
  },
};
</script>

<style></style>
