var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getFilters.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.onReset.apply(null, arguments)}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Tipo de filtro","label-for":"filterType"}},[_c('b-form-select',{staticClass:"form-control",attrs:{"id":"filterType","options":_vm.optionFilterType},model:{value:(_vm.filterType),callback:function ($$v) {_vm.filterType=$$v},expression:"filterType"}})],1)],1),(_vm.filterType !== 'created_at' && _vm.filterType !== 'approved_at')?_c('b-col',[_c('b-form-group',{attrs:{"label":"Texto","label-for":"search-input"}},[_c('b-form-input',{attrs:{"id":"search-input","type":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),(_vm.filterType === 'created_at' || _vm.filterType === 'approved_at')?_c('b-col',[_c('b-form-group',{attrs:{"label":"Intervalo","label-for":"interval"}},[_c('b-form-select',{staticClass:"form-control",attrs:{"id":"interval","options":_vm.optionInterval},on:{"change":_vm.setInterval},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}})],1)],1):_vm._e(),(_vm.interval === 5)?_c('b-col',[_c('b-form-group',{attrs:{"label":"Desde","label-for":"from"}},[_c('b-form-datepicker',{staticClass:"form-control",attrs:{"id":"from","locale":"es","label-help":"","label-no-date-selected":"Elija Fecha","date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1):_vm._e(),(_vm.interval === 5)?_c('b-col',[_c('b-form-group',{attrs:{"label":"Hasta","label-for":"to"}},[_c('b-form-datepicker',{staticClass:"form-control",attrs:{"id":"to","locale":"es","label-help":"","label-no-date-selected":"Elija Fecha","date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1):_vm._e(),_c('b-col',{staticClass:"report-buttons",attrs:{"cols":"1"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Buscar ")])],1),_c('b-col',{staticClass:"report-buttons",attrs:{"cols":"1"}},[_c('b-button',{attrs:{"type":"reset","variant":"secondary"}},[_vm._v(" Limpiar ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{attrs:{"options":_vm.optionsStatus,"value-field":"item","text-field":"name","disabled-field":"notEnabled","aria-describedby":ariaDescribedby,"switches":""},model:{value:(_vm.filterStatusSelected),callback:function ($$v) {_vm.filterStatusSelected=$$v},expression:"filterStatusSelected"}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }