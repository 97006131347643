import * as API from "@/services/API";

export default {
  getBancos() {
    return API.apiClient.get(`/definiciones/bancos`);
  },
  getUT() {
    return API.apiClient.get(`/definiciones/utv`);
  },
  getTipoDocumento() {
    return API.apiClient.get(`/definiciones/tipo_documento`);
  },
  getCuentas() {
    return API.apiClient.get(`/configuraciones/cuentas`);
  },
  getImpuestosSanciones(page) {
    return API.apiClient.get(`/definiciones/impuestoSancion/?page=${page}`);
  },
  getImpuestoSancionPorModulo(modulo) {
    return API.apiClient.get(
      `/definiciones/getImpuestoSancionPorModulo/${modulo}`
    );
  },
  createImpuestoSancion(payload) {
    return API.apiClient.post(`/definiciones/crearImpuestoSancion`, payload);
  },
  updateImpuestoSancion(payload) {
    return API.apiClient.post(`/definiciones/updateImpuestoSancion`, payload);
  },
  deleteImpuestoSancion(id) {
    return API.apiClient.get(`/definiciones/deleteImpuestoSancion/${id}`);
  },
  getUtes(page) {
    return API.apiClient.get(`/definiciones/ute/?page=${page}`);
  },
  createUte(payload) {
    return API.apiClient.post(`/definiciones/createUte`, payload);
  },
  updateUte(payload) {
    return API.apiClient.post(`/definiciones/updateUte`, payload);
  },
  deleteUte(id) {
    return API.apiClient.get(`/definiciones/deleteUte/${id}`);
  },
  paginateUtes(link) {
    return API.apiClient.get(link);
  },
  getUtev() {
    return API.apiClient.get(`/home/utev`);
  },
};
