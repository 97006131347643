<template>
  <b-container>
    <b-container fluid class="estampilla-from">
      <b-row>
        <b-col cols="4"><h5>Solicitudes recientes</h5></b-col>
      </b-row>
      <b-row>
        <b-col col lg="12" md="12">
          <b-card>
            <b-tabs>
              <b-tab title="Forma 001">
                <TabForma type="forma001" />
              </b-tab>
              <b-tab title="Forma 003">
                <TabForma type="forma003" />
              </b-tab>
              <b-tab title="Forma 005">
                <TabForma type="forma005" />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import TabForma from "@/components/Formas/TabForma";

export default {
  components: {
    TabForma,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
