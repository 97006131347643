<template>
  <b-container>
    <b-form @submit.prevent="solicitarForma005">
      <b-container fluid class="estampilla-from">
        <b-row>
          <b-col cols="4"><h5>Solicitud de Forma 005</h5></b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Usuario" label-for="user">
              <b-form-input
                id="user"
                type="text"
                required
                v-model="user"
                autocomplete="user"
                name="user"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedula / RIF" label-for="dni">
              <b-form-input
                id="dni"
                type="text"
                v-model="dni"
                autocomplete="dni"
                name="dni"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tipo de Tramite" label-for="tipoDocumento">
              <b-form-select
                v-model="tipoDocumento"
                :options="optionTramite"
                required
                class="form-control"
                @change="setDynamicLabels"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Forma de Pago" label-for="formaPago">
              <b-form-select
                id="formaPago"
                v-model="formaPago"
                :options="optionFormaPago"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Banco" label-for="banco">
              <b-form-select
                id="banco"
                v-model="cuenta"
                :options="optionCuentas"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="[2, 6].includes(tipoDocumento)">
            <b-form-group label="Cedula de Identidad N°" label-for="cedula">
              <b-form-input
                type="text"
                required
                v-model="cedula"
                name="cedula"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="[1, 2, 3, 6].includes(tipoDocumento)">
            <b-form-group
              label="Nombre(s) de la(s) Persona(s) Natural(es) o Juridica(s) Otorgante(S)"
              label-for="razonSocial"
            >
              <b-form-input
                type="text"
                required
                v-model="razonSocial"
                name="razonSocial"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="[1, 2, 3, 6].includes(tipoDocumento)">
            <b-form-group label="N° de Contribuyente o RIF" label-for="rif">
              <b-form-input
                type="text"
                required
                v-model="rif"
                name="rif"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="tipoDocumento === 9">
            <b-form-group
              label="Nombre del Documento"
              label-for="customDocumento"
            >
              <b-form-input
                type="text"
                required
                v-model="customDocumento"
                name="customDocumento"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="montoLabel" label-for="monto">
              <b-form-input
                type="number"
                required
                v-model="monto"
                name="monto"
                placeholder="Bs."
                step="0.01"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="tipoDocumento">
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-container>
      <FlashMessage :error="error" />
    </b-form>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import FlashMessage from "@/components/FlashMessage";
import { capitalize } from "@/utils/helpers.js";
import { getError } from "@/utils/helpers";
import store from "@/store/index";

export default {
  components: {
    FlashMessage,
  },
  data() {
    return {
      error: null,
      tipoDocumento: null,
      user: null,
      dni: null,
      cuenta: null,
      formaPago: null,
      monto: null,
      rif: null,
      razonSocial: null,
      customDocumento: null,
      montoLabel: null,
      razonSocialLabel: null,
      cedula: null,
      optionTramite: [
        { value: 1, text: "Acta de Asamblea" },
        { value: 2, text: "Constitucion de Sociedades/Aumento de Capital" },
        { value: 3, text: "Estado Financiero" },
        { value: 4, text: "Otorgamientos Otros" },
        { value: 5, text: "Poderes" },
        { value: 6, text: "Reserva de Nombre" },
        { value: 7, text: "Solicitud a Notarias" },
        { value: 8, text: "Ventas de Fondo de Comercio" },
        { value: 9, text: "Especifique" },
      ],
      montoLabels: [
        "Monto Otro Documento",
        "Constitucion de Sociedades/Aumento de Capital",
        "Monto Otro Documento",
        "Otorgamiento de Cualquier",
        "Poderes o Factores Mercantiles",
        "Otorgamiento de Nombre y Denominaciones",
        "Solicitud Dirigida a las Notarias Publicas",
        "Ventas de Fondo de Comercio",
        "Monto Otro Documento",
      ],
      optionFormaPago: [
        { value: "transferencia", text: "Transferencia" },
        // { value: "pago_movil", text: "Pago Móvil" },
      ],
    };
  },
  mounted() {
    this.user = `${capitalize(this.authUser.firstname)} ${capitalize(
      this.authUser.lastname
    )}`;
    this.dni = `${this.authUser.tipoPersona}-${this.authUser.dni}`;
  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters("definiciones", ["cuentas"]),
    optionCuentas: function () {
      const docs = this.cuentas;
      return docs.map((x) => {
        return {
          value: x.id,
          text: `${x.banco.nombre_corto}, Cta. ${capitalize(x.tipo_cuenta)}`,
        };
      });
    },
  },
  methods: {
    async solicitarForma005() {
      try {
        const payload = {
          cuentas_id: this.cuenta,
          forma_pago: this.formaPago,
          monto: this.monto,
          tipo_documento: this.tipoDocumento,
          rif: this.rif,
          razon_social: this.razonSocial,
          cedula: this.cedula,
          documento: this.customDocumento,
          type: "forma005",
        };
        await store.dispatch("forma/solicitarForma", payload);
      } catch (error) {
        this.error = getError(error);
      }
    },
    setDynamicLabels() {
      this.montoLabel = this.montoLabels[this.tipoDocumento - 1];
    },
  },
};
</script>

<style></style>
