<template>
  <b-container>
    <b-modal
      id="listUserService"
      size="xl"
      ref="listUserService"
      name="listUserService"
      centered
      hide-footer
      title="Listado de Empresas"
    >
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Buscar"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="..."
                autofocus="autofocus"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col col lg="6">
          <b-pagination
            v-model="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            align="fill"
            size="sm"
            class="my-0"
            aria-controls="clientesTable"
            @page-click="setClick"
          ></b-pagination>
          <b-row>
            <b-col cols="4">
              <small>Current Page: {{ meta.current_page }}</small>
            </b-col>
            <b-col col offset="5" lg="3" style="text-align: right">
              <small>Total: {{ meta.total }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            id="clientesTable"
            striped
            hover
            sort-icon-left
            ref="listUserService"
            :items="clientes"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            selectable
            select-mode="single"
            @row-selected="rowSelected"
            small
          >
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/index";
export default {
  data() {
    return {
      sortBy: "empresa",
      sortDesc: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      fields: [
        { key: "id", sortable: true, label: "Nro" },
        { key: "rif", sortable: true, label: "Rif" },
        { key: "empresa", sortable: true, label: "Empresa" },
        { key: "placa", sortable: true, label: "Placa" },
        { key: "cedula", sortable: true, label: "Cedula" },
        { key: "chofer", sortable: true, label: "Chofer" },
      ],
    };
  },
  mounted() {
    this.getClientes();
  },
  computed: {
    ...mapGetters("cliente", ["clientes", "meta"]),
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getClientes() {
      try {
        await store.dispatch("cliente/getClientes", this.currentPage);
      } catch (err) {
        console.error(err);
      }
    },
    rowSelected(client) {
      this.$emit("nuevoCliente", client);
      this.$nextTick(() => {
        this.$bvModal.hide("listUserService");
      });
    },
    async setClick(event, page) {
      event.preventDefault();
      await store.dispatch("cliente/paginateClientes", page);
    },
  },
};
</script>

<style></style>
