import * as API from "@/services/API";

export default {
  getTasa(tasaID) {
    return API.apiClient.get(`/tasas/getTasa/=${tasaID}`);
  },
  getTasas(payload) {
    const { tipo, page } = payload;
    return API.apiClient.get(`/tasas/${tipo}/?page=${page}`);
  },
  paginateTasas(payload) {
    const { tipo, page } = payload;
    return API.apiClient.get(`/tasas/${tipo}/?page=${page}`);
  },
  crearTasa(payload) {
    return API.apiClient.post("/tasas/crearTasa", payload);
  },
  anularTasa(payload) {
    return API.apiClient.post(`/tasas/anularTasa`, payload);
  },
  reportOp(tipo) {
    return API.apiClient.get(`/report/reportOp/${tipo}`);
  },
};
