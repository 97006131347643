<template>
  <b-container>
    <b-modal
      id="agregarUsuarioModal"
      ref="agregarUsuarioModal"
      name="agregarUsuarioModal"
      size="xl"
      centered
      hide-footer
      title="Registrar nuevo Ususario"
    >
      <b-form @submit.prevent="registrarUsuario">
        <b-row>
          <b-col>
            <b-form-group label="Nombre" label-for="firstname">
              <b-form-input
                type="text"
                required
                v-model="firstname"
                name="firstname"
                autocomplete="firstname"
                autofocus="autofocus"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Apellido" label-for="lastname">
              <b-form-input
                type="text"
                v-model="lastname"
                name="lastname"
                autocomplete="lastname"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Email" label-for="email">
              <b-form-input
                type="email"
                v-model="email"
                name="email"
                autocomplete="email"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Tipo de Persona" label-for="tipoPersona">
              <b-form-select
                id="tipoPersona"
                v-model="tipoPersona"
                :options="optionTipoPersona"
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cedúla" label-for="dni">
              <b-form-input
                type="number"
                v-model="dni"
                name="dni"
                autocomplete="dni"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Genero" label-for="sexo">
              <b-form-select
                id="sexo"
                v-model="sexo"
                :options="optionSexo"
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Role" label-for="role">
              <b-form-select
                id="role"
                v-model="role"
                :options="optionRole"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Lote" label-for="lote">
              <b-form-select
                id="lote"
                v-model="lote"
                :options="optionLote"
                required
                class="form-control"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Clave" label-for="password">
              <b-form-input
                type="password"
                v-model="password"
                name="password"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Confirmación Clave"
              label-for="confirmPassword"
            >
              <b-form-input
                type="password"
                v-model="confirmPassword"
                name="confirmPassword"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <b-button type="submit" block variant="primary"> Guardar </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import store from "@/store/index";

export default {
  data() {
    return {
      tipoPersona: "V",
      email: null,
      firstname: null,
      lastname: null,
      dni: null,
      sexo: null,
      role: null,
      lote: false,
      password: null,
      confirmPassword: null,
      optionTipoPersona: [
        { value: "V", text: "Natural Venezolano" },
        { value: "E", text: "Natural Extranjero" },
        { value: "J", text: "Jurídica" },
        { value: "G", text: "Gubernamental" },
        { value: "IE", text: "Institución Educativa" },
      ],
      optionRole: [
        { value: 1, text: "Usuario" },
        { value: 2, text: "Tasa de salida " },
        { value: 3, text: "Tasa de desinfeción" },
        { value: 4, text: "Agente Superior" },
        { value: 5, text: "Supervisor" },
        { value: 6, text: "Administrador" },
        { value: 9, text: "Ente Receptor" },
      ],
      optionSexo: [
        { value: "femenino", text: "Femenino" },
        { value: "masculino", text: "Masculino" },
      ],
      optionLote: [
        { value: false, text: "Deshabilitado" },
        { value: true, text: "Habilitado" },
      ],
    };
  },
  computed: {},
  methods: {
    async registrarUsuario() {
      if (this.password === this.confirmPassword) {
        const payload = {
          firstname: this.firstname,
          lastname: this.lastname,
          dni: this.dni,
          tipoPersona: this.tipoPersona,
          sexo: this.sexo,
          role: this.role,
          email: this.email,
          password: this.password,
          lote: this.lote,
        };
        await store.dispatch("user/crearUser", payload);

        this.$emit("nuevoUsuario", payload);
        this.$nextTick(() => {
          this.$bvModal.hide("agregarUsuarioModal");
        });
      } else {
        this.$toast.warning("La clave no coincide", { timeout: 3000 });
      }
    },
  },
};
</script>

<style></style>
